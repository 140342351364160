import React, { Fragment, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useFormContext,
  Controller,
  ControllerRenderProps,
} from 'react-hook-form';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { MobileTestStepDetailContext } from '@contexts/ide/MobileTestStepDetailProvider';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import {
  deviceKeyList,
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { UpdateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { ErrorMessage } from '@hookform/error-message';
import TestStepCheckBoxTable from './TestStepCheckBoxTable/TestStepCheckBoxTable';
import clsx from 'clsx';
import { useCheckbox } from '@autosquare/common';
import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';

interface Props {
  command: string;
}

const TestStepSelectBox = ({ command }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<UpdateTestStepData>();
  const { mobileOS } = useContext(MobileTestStepDetailContext);
  const stepCount =
    mobileOS === DeviceInfoOs.Aos ? 'aosStepCount' : 'iosStepCount';

  const isUiTestLocation = isWordInLocationPathname('ui-test');
  const mobileTestCaseList = isUiTestLocation
    ? useMobileTestCaseListQuery({
        enabled:
          isUiTestLocation &&
          command === MobileCommandOptionServerName.ImportTestCase,
      })
    : useSpeedMobileTestCaseListQuery({
        enabled:
          !isUiTestLocation &&
          command === MobileCommandOptionServerName.ImportTestCase,
      });

  const mobileTestCaseListData = mobileTestCaseList.data?.filter(
    (mobileTestCase) => mobileTestCase?.idx !== idx,
  );

  const selectedIdxList = watch('optionsDto.stepIdxList')
    ?.split(',')
    ?.map((item) => Number(item));
  const caseIdx = watch('value');
  const mobileTestStepListQuery = useMobileTestStepByCaseQuery(caseIdx, {
    enabled: caseIdx !== undefined,
  });
  const mobileOs = watch('mobileOs');
  const mobileTestStepListWithoutDisabled = mobileTestStepListQuery.data?.[
    mobileOs
  ]?.filter(
    (item) =>
      !(
        item.command.includes('import') ||
        (item.command === MobileCommandOptionServerName.IF &&
          item.value.includes('import'))
      ),
  );

  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckbox(mobileTestStepListWithoutDisabled, 'idx', selectedIdxList);

  const onChangeHandler = (
    field: ControllerRenderProps<UpdateTestStepData, 'value'>,
    value: string,
  ) => {
    if (command === MobileCommandOptionServerName.ImportTestStep) {
      resetSelectedArray();
    }
    field.onChange(value);
  };

  return (
    <div className="flex items-start border-b border-gray-200 sm:px-6 sm:py-5">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value
      </dt>
      <div className="flex w-4/6 flex-col">
        <dd className="mt-1 w-full text-sm text-gray-900 sm:ml-6 sm:mt-0">
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <Listbox
                as="div"
                value={field.value}
                onChange={(value) => onChangeHandler(field, value)}
                className="w-full"
              >
                {({ open }) => (
                  <>
                    <div className="relative w-full">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {command === MobileCommandOptionServerName.DeviceKey
                            ? findListNameFromServerName(
                                field.value as DeviceKeyServerName,
                              )
                            : mobileTestCaseListData?.some(
                                  (mobileTestCase) =>
                                    mobileTestCase?.title === field.value,
                                )
                              ? field.value
                              : mobileTestCaseListData?.find(
                                  (mobileTestCase) =>
                                    mobileTestCase?.idx === Number(field.value),
                                )?.title}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="size-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions className="absolute z-10 mt-1 max-h-96 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                          {command ===
                            MobileCommandOptionServerName.DeviceKey &&
                          mobileOS === DeviceInfoOs.Aos
                            ? deviceKeyList.slice(1).map((deviceKey) => (
                                <ListboxOption
                                  key={deviceKey.idx}
                                  className={clsx(
                                    'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                                  )}
                                  value={deviceKey.serverName}
                                >
                                  <span
                                    className={clsx(
                                      'block break-all font-normal group-data-[selected]:font-medium',
                                    )}
                                  >
                                    {deviceKey.listName}
                                  </span>
                                  <span
                                    className={clsx(
                                      'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                    )}
                                  >
                                    <CheckIcon
                                      className="invisible size-5 group-data-[selected]:visible"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </ListboxOption>
                              ))
                            : command ===
                                  MobileCommandOptionServerName.DeviceKey &&
                                mobileOS === DeviceInfoOs.Ios
                              ? deviceKeyList.slice(2).map((deviceKey) => (
                                  <ListboxOption
                                    key={deviceKey.idx}
                                    className={clsx(
                                      'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                                    )}
                                    value={deviceKey.serverName}
                                  >
                                    <span
                                      className={clsx(
                                        'block break-all font-normal group-data-[selected]:font-medium',
                                      )}
                                    >
                                      {deviceKey.listName}
                                    </span>
                                    <span
                                      className={clsx(
                                        'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                      )}
                                    >
                                      <CheckIcon
                                        className="invisible size-5 group-data-[selected]:visible"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </ListboxOption>
                                ))
                              : mobileTestCaseListData?.map(
                                  (mobileTestCase) => (
                                    <ListboxOption
                                      key={mobileTestCase?.idx}
                                      className={clsx(
                                        'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[disabled]:text-gray-300 data-[focus]:text-white',
                                      )}
                                      value={mobileTestCase?.idx}
                                      disabled={
                                        command ===
                                          MobileCommandOptionServerName.ImportTestStep &&
                                        (mobileTestCase.idx === idx ||
                                          mobileTestCase.steps[stepCount] === 0)
                                      }
                                    >
                                      <span
                                        className={clsx(
                                          'block break-all font-normal group-data-[selected]:font-medium',
                                        )}
                                      >
                                        {mobileTestCase?.title}
                                      </span>
                                      <span
                                        className={clsx(
                                          'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                        )}
                                      >
                                        <CheckIcon
                                          className="invisible size-5 group-data-[selected]:visible"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </ListboxOption>
                                  ),
                                )}
                        </ListboxOptions>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          />
        </dd>
        {command === MobileCommandOptionServerName.ImportTestStep && (
          <>
            <TestStepCheckBoxTable
              selectAllArray={selectAllArray}
              selectedArray={selectedArray}
              selectArray={selectArray}
              query={mobileTestStepListQuery}
            />
            <ErrorMessage
              name="optionsDto.stepIdxList"
              errors={errors}
              render={({ message }) => (
                <p className="standard-error-message !ml-6">{message}</p>
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TestStepSelectBox;
