import TestStepValueListbox from '@components/shared/IDE/TestStepValueListbox';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useTestStepValueList } from '@hooks/testStep/useTestStepValueList';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const SelectedElement = () => {
  const { setConditionValue, isListboxOpened, setIsListboxOpened } =
    useMobileTestStepContext();

  const { watch, control, resetField, setValue } =
    useFormContext<CreateTestStepData>();

  const conditionCommand = watch('optionsDto.conditionCommand');
  const conditionValue = watch('optionsDto.conditionValue');

  const { totalValues, bounds } = useTestStepValueList({
    setValue: setConditionValue,
    command: conditionCommand,
  });

  useEffect(() => {
    setValue('optionsDto.conditionValue', undefined);
    return () => {
      setValue('optionsDto.conditionValue', undefined);
      setValue('optionsDto.conditionOptions', undefined);
    };
  }, [setValue]);

  useEffect(() => {
    setValue('optionsDto.conditionOptions.elementOptions.bounds', bounds);
  }, [bounds, setValue]);

  return (
    <Controller
      control={control}
      name={'optionsDto.conditionValue'}
      rules={{ required: 'Condition Value를 선택해주세요.' }}
      render={({ field }) => (
        <TestStepValueListbox
          valueText={field.value || 'Value'}
          setValueText={field.onChange}
          totalValues={totalValues}
          isListboxOpened={isListboxOpened}
          setIsListboxOpened={setIsListboxOpened}
          resetOnClick={() => {
            resetField('optionsDto.conditionCommand');
            setValue('optionsDto.conditionValue', undefined);
          }}
          disabled={
            conditionCommand === undefined || conditionValue !== undefined
          }
        />
      )}
    />
  );
};

export default SelectedElement;
