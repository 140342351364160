import { ErrorMessage, ImportTestStepTableSkeleton } from '@autosquare/common';
import ImportTestStepTable from '@components/shared/TestStep/ImportTestStepTable';
import {
  MobileTestStepByCase,
  MobileTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

type Props = {
  query: UseQueryResult<MobileTestStepByCase, Error>;
  hasCheckBox?: boolean;
  headers: (string | React.JSX.Element)[];
  rows: MobileTestStepData[];
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
};

const MobileImportTestStepTable = ({
  query,
  headers,
  rows,
  selectArray,
  selectedArray,
  hasCheckBox = true,
}: Props) => {
  return (
    <>
      {query.isLoading || query.isFetching ? (
        <ImportTestStepTableSkeleton hasCheckBox={hasCheckBox} />
      ) : query.isError ? (
        <ErrorMessage>{query.error.message}</ErrorMessage>
      ) : (
        <ImportTestStepTable
          headers={headers}
          rows={rows}
          selectArray={selectArray}
          selectedArray={selectedArray}
        />
      )}
    </>
  );
};

export default MobileImportTestStepTable;
