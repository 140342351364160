import React from 'react';
import { useFormContext } from 'react-hook-form';

import DefinitionList from '@components/shared/DefinitionList';
import TestStepListValue from '@components/TestStepCommon/TestStepListValue';

import { ClickId } from '@utils/static/clickOptions';

import {
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import DefinitionTextAreaByReactHookForm from '@components/shared/TestStepDetail/DefinitionTextAreaByReactHookForm';
import ClickCoordinateOptionEdit from '@components/shared/TestStepDetail/ClickCoordinateOptionEdit';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const TestStepClickOption = ({ mobileTestStep }: Props) => {
  const { watch } = useFormContext<UpdateTestStepData>();

  const mobileScreenWidth = Number(
    mobileTestStep?.optionsDto?.screenSize.split('x')[0],
  );
  const mobileScreenHeight = Number(
    mobileTestStep?.optionsDto?.screenSize.split('x')[1],
  );
  const xCoordinate = mobileTestStep?.value?.split(',')[0];
  const yCoordinate = mobileTestStep?.value?.split(',')[1];

  return (
    <>
      {watch('optionsDto.clickBy') === ClickId.Default ? (
        <DefinitionTextAreaByReactHookForm term={'Value*'} name={'value'} />
      ) : watch('optionsDto.clickBy') === ClickId.Coordinate ? (
        <div className="items-center sm:flex sm:px-6 sm:py-4">
          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
            Value*
          </dt>
          <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ClickCoordinateOptionEdit
              imageWidth={mobileScreenWidth}
              imageHeight={mobileScreenHeight}
              xCoordinate={xCoordinate}
              yCoordinate={yCoordinate}
            />
          </dd>
        </div>
      ) : (
        <DefinitionList
          term={'Value'}
          description={<TestStepListValue imageValue={mobileTestStep?.value} />}
        />
      )}
    </>
  );
};

export default TestStepClickOption;
