import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { WebTestStepData } from '@customTypes/testStep/type';

export const useImportTestStep = (
  stepList: (WebTestStepData | MobileTestStepData)[],
  selectedArray: number[],
) => {
  const selectedTestStep = stepList?.filter((step) =>
    selectedArray.includes(step.idx),
  );
  const stepsWithCompareIdx = selectedTestStep?.filter(
    (item) => item.optionsDto.compareIdx,
  );

  const compareIdxArray = stepsWithCompareIdx?.map((item) =>
    Number(item.optionsDto.compareIdx),
  );
  const compareIdxArraySet = new Set(compareIdxArray);

  const uniqueCompareIdxArray = [...compareIdxArraySet];

  return { compareIdxArraySet, uniqueCompareIdxArray };
};
