import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ClickId, clickOptions } from '@utils/static/clickOptions';

import CheckboxHookForm from '@components/shared/Input/CheckboxHookForm';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import TestStepClickCoordinate from './SelectObjectOrCoordinate/TestStepClickCoordinate';
import TestStepValue from './SelectObjectOrCoordinate/TestStepValue';
import ComparingImageInClick from './SelectObjectOrCoordinate/ComparingImageInClick';

const SelectObjectOrCoordinate = () => {
  const { watch, setValue, register } = useFormContext<CreateTestStepData>();

  const command = watch('command');
  const clickBy = watch('optionsDto.clickBy');
  useEffect(() => {
    if (command === MobileCommandOptionServerName.Click) {
      setValue('optionsDto.clickBy', ClickId.Default);
    }
    return () => {
      setValue('optionsDto.clickBy', undefined);
    };
  }, [command, setValue]);

  useEffect(() => {
    if (command === MobileCommandOptionServerName.Click) {
      setValue('value', undefined);
      return () => setValue('value', undefined);
    }
  }, [command, clickBy, setValue]);

  return (
    <>
      {clickBy !== undefined && (
        <fieldset>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
            {command === MobileCommandOptionServerName.Click && (
              <>
                <legend className="text-sm text-gray-900">
                  클릭 대상 선택:
                </legend>
                {clickOptions.map((inputOption) => (
                  <CheckboxHookForm
                    key={inputOption.id}
                    id={inputOption.id}
                    name={'clickOptions'}
                    defaultChecked={inputOption.defaultChecked}
                    labelTitle={inputOption.labelTitle}
                    register={register('optionsDto.clickBy')}
                    onClick={() => setValue('value', undefined)}
                  />
                ))}
              </>
            )}
          </div>
        </fieldset>
      )}
      {clickBy === ClickId.Coordinate ? (
        <TestStepClickCoordinate />
      ) : clickBy === ClickId.Image ||
        command === MobileCommandOptionServerName.CompareImage ? (
        <ComparingImageInClick />
      ) : (
        <TestStepValue />
      )}
    </>
  );
};

export default SelectObjectOrCoordinate;
