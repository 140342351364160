import { ArrowSpinner } from '@autosquare/common';

import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SubmitHandler, useFormContext } from 'react-hook-form';

import { useCreateMobileTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { ClickId } from '@utils/static/clickOptions';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { AppInfoSource, DeviceInfoSource } from '@customTypes/testStep/type';
import { SaveTextFrom } from '@customTypes/ide/mobileTestStep/SaveTextFrom';

import MobileTestStepForm from './AddTestStep/MobileTestStepForm';
import { useCreateSpeedMobileTestStep } from '@lib/api/ide/speedMobile/speedMobileTestStep';

interface Props {
  deviceInfo: DeviceInfoSource;
  appInfo: AppInfoSource;
}

const AddTestStep = ({ deviceInfo, appInfo }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const { pathname } = useLocation();

  const { setValue, handleSubmit, reset, setError } =
    useFormContext<CreateTestStepData>();

  const needToDefaultValues = () => {
    setValue('packageName', appInfo?.package_name);
    setValue('appVersion', appInfo?.app_version);
    setValue('optionsDto.udid', deviceInfo?.udid);
    setValue('optionsDto.model', deviceInfo?.model);
    setValue('optionsDto.screenSize', deviceInfo?.physical_size);
    setValue('optionsDto.overrideSize', deviceInfo?.override_size);
    setValue('optionsDto.dpi', deviceInfo?.physical_dpi);
    setValue('optionsDto.overrideDpi', deviceInfo?.override_dpi);
    setValue('optionsDto.viewportSize', deviceInfo?.viewport_size);
    setValue('optionsDto.waitingTime', '2');
    setValue('required', true);
    setValue('optionsDto.actionOptions', undefined);
    setValue('optionsDto.passActionOptions', undefined);
    setValue('optionsDto.failActionOptions', undefined);
    setValue('optionsDto.stepIdxList', undefined);
  };

  useEffect(() => {
    needToDefaultValues();
  }, [appInfo, deviceInfo, setValue]);

  const createTestStepMutation = pathname.includes('ui-test')
    ? useCreateMobileTestStepMutation(idx)
    : useCreateSpeedMobileTestStep(idx);

  const onSubmit: SubmitHandler<CreateTestStepData> = (data) => {
    if (
      (data.command === MobileCommandOptionServerName.Click &&
        data.optionsDto.clickBy === ClickId.Default) ||
      data.command === MobileCommandOptionServerName.FindElement ||
      (data.command === MobileCommandOptionServerName.SaveText &&
        data.optionsDto.from === SaveTextFrom.Element) ||
      data.command === MobileCommandOptionServerName.ConfirmText ||
      data.command === MobileCommandOptionServerName.InputText ||
      data.command === MobileCommandOptionServerName.VerifyNoElement
    ) {
      data.value = `xpath=${data.value}`;
    }

    if (
      data.command === MobileCommandOptionServerName.Loop &&
      data.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.VerifyNoElement
    ) {
      data.optionsDto.conditionValue = `xpath=${data.optionsDto.conditionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.Loop &&
      data.value === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.actionValue = `xpath=${data.optionsDto.actionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      (data.optionsDto.conditionCommand ===
        MobileCommandOptionServerName.FindElement ||
        data.optionsDto.conditionCommand ===
          MobileCommandOptionServerName.VerifyNoElement)
    ) {
      data.optionsDto.conditionValue = `xpath=${data.optionsDto.conditionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      data.value.split(',')[0] === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.passActionValue = `xpath=${data.optionsDto.passActionValue}`;
    }

    if (
      data.command === MobileCommandOptionServerName.IF &&
      data.value.split(',')[1] !== undefined &&
      data.value.split(',')[1] === MobileCommandOptionServerName.Click
    ) {
      data.optionsDto.failActionValue = `xpath=${data.optionsDto.failActionValue}`;
    }

    if (
      (data.command === MobileCommandOptionServerName.Click &&
        data.optionsDto.clickBy === ClickId.Default &&
        data.optionsDto.textReplace === 'true' &&
        data.optionsDto.textReplaceOptions.dateFormat === '') ||
      (data.command === MobileCommandOptionServerName.ConfirmText &&
        data.optionsDto.textReplace === 'true' &&
        data.optionsDto.textReplaceOptions.dateFormat === '')
    ) {
      setError('optionsDto.textReplaceOptions.dateFormat', {
        type: 'manual',
        message: 'Date Format의 년, 월, 일 중 1가지 이상 선택해 주세요.',
      });
    } else if (
      data.command === MobileCommandOptionServerName.UnlockPattern &&
      data.value?.split(',')?.length < 4
    ) {
      setError('value', {
        type: 'manual',
        message: '2개 이상의 좌표를 선택해주세요.',
      });
    } else {
      createTestStepMutation.mutate(data, {
        onSuccess: () => {
          reset();
          needToDefaultValues();
        },
      });
    }
  };

  return (
    <>
      <form
        className="gray-scrollbar col-span-3 flex h-full flex-col gap-4 border p-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <MobileTestStepForm
          isError={createTestStepMutation.isError}
          error={createTestStepMutation.error}
        />
      </form>
      {createTestStepMutation.isLoading && <ArrowSpinner />}
    </>
  );
};

export default AddTestStep;
