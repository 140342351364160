import { CommonDialog } from '@components/Dialog';
import { WebTestCaseDetailData } from '@customTypes/testCase/type';
import {
  CopyWebTestStep,
  copyWebTestStepSchema,
} from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import { useCopyWebTestStepMutation } from '@lib/api/ide/web/webTestStep';
import { findTitleFromIdx } from '@utils/static/findTitleFromIdx';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArray: number[];
  uniqueImportTestIdxList: number[];
};

const WebTestStepCopyDialog = ({
  isOpen,
  setIsOpen,
  selectedArray,
  uniqueImportTestIdxList,
}: Props) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CopyWebTestStep>({
    resolver: zodResolver(copyWebTestStepSchema),
  });

  const targetCaseIdx = watch('targetCaseIdx');

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [caseIdx, setCaseIdx] = useState<number | undefined>(undefined);
  const [query, setQuery] = useState('');

  const copyWebTestStepMutation = useCopyWebTestStepMutation();

  const onSubmit: SubmitHandler<CopyWebTestStep> = (data) => {
    copyWebTestStepMutation.mutate(data, {
      onSuccess: () => {
        setCaseIdx(targetCaseIdx);
        setIsSuccess(true);
      },
      onError: () => {
        setIsError(true);
      },
    });
    setIsOpen(false);
  };

  const { data: webTestCaseList } = useWebTestCaseListQuery({
    enabled: isOpen,
  });

  useEffect(() => {
    if (isOpen) {
      setValue('copyIdxList', selectedArray);
    }
  }, [isOpen, selectedArray]);

  useEffect(() => {
    if (!isOpen && webTestCaseList) {
      setTimeout(() => {
        setValue('copyIdxList', undefined);
        setValue('targetCaseIdx', undefined);
        clearErrors('targetCaseIdx');
      }, 400);
    }
  }, [isOpen, webTestCaseList]);

  const goToCopiedStepOnClick = () => {
    navigate({ search: `idx=${caseIdx}` });
    setCaseIdx(undefined);
    setIsSuccess(false);
  };

  const filteredTestCaseList =
    query === ''
      ? webTestCaseList
      : webTestCaseList.filter((webTestCase) =>
          webTestCase.title.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <>
      <CommonDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        iconType={'copy'}
        title={'Copy Test Step'}
        subTitle={'해당 테스트 스텝을 복사할 테스트 케이스를 선택해 주세요.'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonDialog.DialogPanels>
            <Controller
              control={control}
              name="targetCaseIdx"
              render={({ field }) => (
                <CommonDialog.DialogCombobox
                  value={field.value || ''}
                  onChange={field.onChange}
                  setQuery={setQuery}
                  displayValue={(idx: number) =>
                    findTitleFromIdx(idx, webTestCaseList) || query
                  }
                  filteredList={filteredTestCaseList}
                  valueToSave={'idx'}
                  valueToShow={'title'}
                  placeholder={'Select Test Case'}
                  isOptionDisabled={(item: WebTestCaseDetailData) =>
                    uniqueImportTestIdxList.includes(item.idx)
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="targetCaseIdx"
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </CommonDialog.DialogPanels>
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="submit"
              disabled={copyWebTestStepMutation.isLoading}
            >
              Copy
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </form>
      </CommonDialog>
      <CommonDialog
        isOpen={isSuccess}
        setIsOpen={setIsSuccess}
        iconType={'copy'}
        title={'Test step copy complete'}
        subTitle={'테스트 스텝 복사가 완료되었습니다.'}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            variant="primary"
            type="button"
            onClick={goToCopiedStepOnClick}
          >
            Go to copied step
          </CommonDialog.DialogButton>
          <CommonDialog.DialogButton
            variant="secondary"
            type="button"
            onClick={() => setIsSuccess(false)}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isError}
        setIsOpen={setIsError}
        iconType={'caution'}
        title={'Error'}
        subTitle={
          copyWebTestStepMutation.isError &&
          copyWebTestStepMutation.error.message
        }
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            variant="primary"
            type="button"
            onClick={() => setIsError(false)}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </>
  );
};

export default WebTestStepCopyDialog;
