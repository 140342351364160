import {
  BasicAlert,
  ClipBoardButton,
  DoubleCheckDialogHeadlessUI,
  Button,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDeleteWebTestStepMutation } from '@lib/api/ide/web/webTestStep';

import {
  findWebCommandNameFromServerName,
  WebCommandOptionServerName,
} from '@utils/static/webCommandOptions';

import { WebTestStepData } from '@customTypes/testStep/type';

import DescriptionData from '@components/shared/IDE/WebTestStep/DescriptionData';

import IfDetail from './WebTestStepListDetails/IfDetail';

type Props = {
  allWebTestStepList: WebTestStepData;
  value: string;
};

const WebTestStepListDetails = ({ allWebTestStepList, value }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const deleteWebTestStepMutation = useDeleteWebTestStepMutation({
    caseIdx: idx,
    idxList: [allWebTestStepList?.idx],
  });

  const handleCopy = () => {
    if (
      allWebTestStepList?.command === WebCommandOptionServerName.KeyBoardEvent
    ) {
      window.navigator.clipboard.writeText(
        allWebTestStepList?.optionsDto.targetElement,
      );
    } else {
      window.navigator.clipboard.writeText(value);
    }

    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <>
      <div className="text-sm text-gray-500">
        <div className="border border-gray-200">
          <dl className="divide-y divide-gray-100">
            <DescriptionData
              title={'Command'}
              data={findWebCommandNameFromServerName(
                allWebTestStepList?.command,
              )}
            />
            <DescriptionData
              title={'Description'}
              data={allWebTestStepList?.description}
            />
            {allWebTestStepList?.command !== WebCommandOptionServerName.IF && (
              <DescriptionData
                title={'Value'}
                data={
                  <>
                    {value}
                    {allWebTestStepList?.command !==
                      WebCommandOptionServerName.KeyBoardEvent &&
                      allWebTestStepList?.command !==
                        WebCommandOptionServerName.ImportTestStep && (
                        <ClipBoardButton onClick={handleCopy} />
                      )}
                  </>
                }
              />
            )}
            {allWebTestStepList?.command ===
              WebCommandOptionServerName.ScrollAndSwipe && (
              <DescriptionData
                title={'Repeat'}
                data={allWebTestStepList?.optionsDto.loop}
              />
            )}
            {allWebTestStepList?.command ===
              WebCommandOptionServerName.KeyBoardEvent && (
              <DescriptionData
                title={'Target Element'}
                data={
                  <>
                    {allWebTestStepList?.optionsDto.targetElement}
                    <ClipBoardButton onClick={handleCopy} />
                  </>
                }
              />
            )}
            {allWebTestStepList?.command === WebCommandOptionServerName.IF ? (
              <IfDetail
                allWebTestStepList={allWebTestStepList}
                setIsCopied={setIsCopied}
              />
            ) : (
              <DescriptionData
                title={'Required'}
                data={allWebTestStepList?.required === true ? 'Y' : 'N'}
              />
            )}
            <DescriptionData
              title={'WaitTime'}
              data={allWebTestStepList?.optionsDto?.waitingTime}
            />
            <div className="flex items-center justify-end px-3 py-2 odd:bg-gray-50 even:bg-white">
              <div className="text-xs leading-6 text-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-5 cursor-pointer text-gray-700"
                  onClick={() => setIsModalOpened(true)}
                >
                  <path
                    fillRule="evenodd"
                    d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <DoubleCheckDialogHeadlessUI
        isOpened={isModalOpened}
        setIsOpened={setIsModalOpened}
        type={'caution'}
        title={deleteWebTestStepMutation.isError ? 'Error' : 'Delete Test Step'}
        subTitle={
          deleteWebTestStepMutation.isError
            ? deleteWebTestStepMutation.error.message
            : '해당 테스트 스텝을 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteWebTestStepMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() => deleteWebTestStepMutation.mutate()}
              >
                Delete
              </Button>
            )}
            <Button
              type="button"
              variant="secondary"
              onClick={() => setIsModalOpened(false)}
            >
              Cancel
            </Button>
          </>
        }
      />
      {isCopied && <BasicAlert phrase="클립보드에 복사되었습니다." />}
    </>
  );
};

export default WebTestStepListDetails;
