import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useDispatch } from 'react-redux';

import { hasSchedulerRunning } from '@store/ide/scheduler/schedulerSlice';
import clsx from 'clsx';

type Props = {
  isRunning: boolean;
  setIsRunning: Dispatch<SetStateAction<boolean>>;
};

const SchedulerDetailStatus = ({ isRunning, setIsRunning }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hasSchedulerRunning(isRunning));
  }, [isRunning]);

  return (
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4">
        <div
          className="text-sm font-medium leading-6 text-gray-900"
          aria-hidden="true"
        >
          Status
        </div>
        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
          <Switch
            checked={isRunning}
            onChange={setIsRunning}
            className={clsx(
              'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              isRunning ? 'bg-congress-blue' : 'bg-gray-200',
            )}
          >
            <span className="sr-only">Scheduler Status</span>
            <span
              aria-hidden="true"
              className={clsx(
                'pointer-events-none inline-block size-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                isRunning ? 'translate-x-5' : 'translate-x-0',
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default SchedulerDetailStatus;
