import { ListBoxHeadlessUI } from '@autosquare/common';

import WebTestStepImportTestCaseListBox from '@components/shared/IDE/WebTestStep/WebTestStepImportTestCaseListBox';
import GetUrlListBox from '@components/shared/IDE/WebTestStep/GetUrlListBox';
import BrowserKeyListBox from '@components/shared/IDE/WebTestStep/BrowserKeyListBox';

import { useWebTestStepContext } from '@contexts/ide/WebTestStepProvider';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import { CreateWebTestStepData } from '@customTypes/testStep/type';

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ClickId } from '@utils/static/clickOptions';

const WebTestStepValueLine = () => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  const { fullXPath } = useWebTestStepContext();

  const command = watch('command');

  useEffect(() => {
    if (command === WebCommandOptionServerName.Click) {
      setValue('optionsDto.clickBy', ClickId.Default);
    } else {
      setValue('optionsDto.clickBy', undefined);
    }
  }, [command]);

  return (
    <>
      {command === WebCommandOptionServerName.ImportTestCase ? (
        <WebTestStepImportTestCaseListBox value="value" />
      ) : command === WebCommandOptionServerName.GetUrl ? (
        <GetUrlListBox />
      ) : command === WebCommandOptionServerName.BrowserKey ? (
        <BrowserKeyListBox />
      ) : (
        <>
          <Controller
            control={control}
            name={'value'}
            rules={{ required: 'Value를 선택해 주세요.' }}
            render={({ field }) => (
              <ListBoxHeadlessUI
                state={field.value || 'Value'}
                setState={field.onChange}
                list={fullXPath}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="value"
            render={({ message }) => (
              <p className="error-message !mt-2">{message}</p>
            )}
          />
        </>
      )}
    </>
  );
};

export default WebTestStepValueLine;
