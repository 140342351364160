import { useInput } from '@autosquare/common';

import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';

interface MobileTestStepProviderContext {
  startX: number;
  onChangeStartX: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setStartX: React.Dispatch<React.SetStateAction<number>>;
  startY: number;
  onChangeStartY: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setStartY: React.Dispatch<React.SetStateAction<number>>;
  endX: number;
  onChangeEndX: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setEndX: React.Dispatch<React.SetStateAction<number>>;
  endY: number;
  onChangeEndY: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  setEndY: React.Dispatch<React.SetStateAction<number>>;
  imageWidth: number;
  setImageWidth: React.Dispatch<React.SetStateAction<number>>;
  imageHeight: number;
  setImageHeight: React.Dispatch<React.SetStateAction<number>>;
  isListboxOpened: boolean;
  setIsListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isPassListboxOpened: boolean;
  setIsPassListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isFailListboxOpened: boolean;
  setIsFailListboxOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isCountThreeNeeded: boolean;
  setIsCountThreeNeeded: React.Dispatch<React.SetStateAction<boolean>>;
  countDown: number;
  setCountDown: React.Dispatch<React.SetStateAction<number>>;
  conditionValue: string;
  setConditionValue: React.Dispatch<React.SetStateAction<string>>;
  isLandscapeMode: boolean;
  isComparingRectHidden: boolean;
  setIsComparingRectHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileTestStepContext =
  createContext<MobileTestStepProviderContext>({
    startX: null,
    onChangeStartX: () => {},
    setStartX: () => {},
    startY: null,
    onChangeStartY: () => {},
    setStartY: () => {},
    endX: null,
    onChangeEndX: () => {},
    setEndX: () => {},
    endY: null,
    onChangeEndY: () => {},
    setEndY: () => {},
    imageWidth: 0,
    setImageWidth: () => {},
    imageHeight: 0,
    setImageHeight: () => {},
    isListboxOpened: true,
    setIsListboxOpened: () => {},
    isPassListboxOpened: true,
    setIsPassListboxOpened: () => {},
    isFailListboxOpened: true,
    setIsFailListboxOpened: () => {},
    isCountThreeNeeded: false,
    setIsCountThreeNeeded: () => {},
    countDown: 3,
    setCountDown: () => {},
    conditionValue: undefined,
    setConditionValue: () => {},
    isLandscapeMode: false,
    isComparingRectHidden: true,
    setIsComparingRectHidden: () => {},
  });

export const useMobileTestStepContext = () => {
  return useContext(MobileTestStepContext);
};

interface Props {
  children: ReactNode;
}

const MobileTestStepProvider = ({ children }: Props) => {
  const [startX, onChangeStartX, setStartX] = useInput<number>(0);
  const [startY, onChangeStartY, setStartY] = useInput<number>(0);
  const [endX, onChangeEndX, setEndX] = useInput<number>(0);
  const [endY, onChangeEndY, setEndY] = useInput<number>(0);

  // image width heigth 저장
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const [conditionValue, setConditionValue] = useState<string>(undefined);

  const [isListboxOpened, setIsListboxOpened] = useState(true);
  const [isPassListboxOpened, setIsPassListboxOpened] = useState(true);
  const [isFailListboxOpened, setIsFailListboxOpened] = useState(true);
  const [isComparingRectHidden, setIsComparingRectHidden] = useState(true);

  const [isCountThreeNeeded, setIsCountThreeNeeded] = useState(false);
  const [countDown, setCountDown] = useState(3);

  const isLandscapeMode = imageWidth > imageHeight ? true : false;

  const contextValue = useMemo(
    () => ({
      startX,
      onChangeStartX,
      setStartX,
      startY,
      onChangeStartY,
      setStartY,
      endX,
      onChangeEndX,
      setEndX,
      endY,
      onChangeEndY,
      setEndY,
      imageWidth,
      setImageWidth,
      imageHeight,
      setImageHeight,
      isListboxOpened,
      setIsListboxOpened,
      isPassListboxOpened,
      setIsPassListboxOpened,
      isFailListboxOpened,
      setIsFailListboxOpened,
      isCountThreeNeeded,
      setIsCountThreeNeeded,
      countDown,
      setCountDown,
      conditionValue,
      setConditionValue,
      isLandscapeMode,
      isComparingRectHidden,
      setIsComparingRectHidden,
    }),
    [
      startX,
      startY,
      endX,
      endY,
      imageWidth,
      imageHeight,
      isListboxOpened,
      isPassListboxOpened,
      isFailListboxOpened,
      isCountThreeNeeded,
      countDown,
      conditionValue,
      isLandscapeMode,
      isComparingRectHidden,
    ],
  );

  return (
    <MobileTestStepContext.Provider value={contextValue}>
      {children}
    </MobileTestStepContext.Provider>
  );
};

export default MobileTestStepProvider;
