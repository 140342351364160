import React, { createContext, ReactNode, useContext, useState } from 'react';

interface DeviceSettingsProviderContext {
  checkFilenameExtension: boolean;
  setCheckFilenameExtension: React.Dispatch<React.SetStateAction<boolean>>;
  isInsideFile: boolean;
  setIsInsideFile: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenedDialog: boolean;
  setIsOpenedDialog: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  openDialog: () => void;
  closeDialog: () => void;
}

export const DeviceSettingsContext =
  createContext<DeviceSettingsProviderContext>({
    checkFilenameExtension: false,
    setCheckFilenameExtension: () => {},
    isInsideFile: false,
    setIsInsideFile: () => {},
    isOpenedDialog: false,
    setIsOpenedDialog: () => {},
    fileName: '',
    setFileName: () => {},
    openDialog: () => {},
    closeDialog: () => {},
  });

export const useDeviceSettingsContext = () => {
  return useContext(DeviceSettingsContext);
};

interface Props {
  children: ReactNode;
}

const DeviceSettingsProvider = ({ children }: Props) => {
  const [checkFilenameExtension, setCheckFilenameExtension] = useState(false);
  const [isInsideFile, setIsInsideFile] = useState(false);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [fileName, setFileName] = useState('');

  const openDialog = () => {
    setIsOpenedDialog(true);
  };

  const closeDialog = () => {
    setIsOpenedDialog(false);
  };

  return (
    <DeviceSettingsContext.Provider
      value={{
        checkFilenameExtension,
        setCheckFilenameExtension,
        isInsideFile,
        setIsInsideFile,
        isOpenedDialog,
        setIsOpenedDialog,
        fileName,
        setFileName,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </DeviceSettingsContext.Provider>
  );
};

export default DeviceSettingsProvider;
