import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { RootState } from '@app/store';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { getCroppedImage } from '@utils/imageUtils';

const ComparingImageInClick = () => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
  } = useMobileTestStepContext();

  const { setValue } = useFormContext<CreateTestStepData>();

  const screenshot = useSelector(
    (state: RootState) => state.mobileScreenshotSource.screenshotSource,
  );

  useEffect(() => {
    return () => {
      setValue('value', undefined);
      setValue('optionsDto.cropCoordinate', undefined);
    };
  }, [setValue]);

  useEffect(() => {
    setValue(
      'optionsDto.cropCoordinate',
      `${startX},${startY},${endX},${endY}`,
    );
    setValue(
      'value',
      getCroppedImage(
        startX,
        startY,
        Math.abs(endX - startX),
        Math.abs(endY - startY),
        screenshot,
      ).split(',')[1],
    );
  }, [startX, startY, endX, endY]);

  const rectProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <PointOrRectangleInput
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      properties={rectProperties}
      isHiddenInputArrow={true}
    />
  );
};

export default ComparingImageInClick;
