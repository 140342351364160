import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  ImportTestStepComboboxHeadlessInController,
  useCheckbox,
} from '@autosquare/common';
import { ErrorMessage } from '@hookform/error-message';
import { useImportTestStep } from '@hooks/testStep/useImportTestStep';
import { Controller, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ImportTestStepFilteredList } from '@autosquare/common/src/utils/type';
import { findTitleFromIdx } from '@utils/static/findTitleFromIdx';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { RootState } from '@app/store';
import { useSelector } from 'react-redux';
import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { useMobileTestStepByCaseQuery } from '@lib/api/ide/mobile/mobileTestStep';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import MobileImportTestStepTable from './MobileImportTestStepCommandList/MobileImportTestStepTable';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import {
  filterSelectedIdx,
  removeMobileUnusedFields,
} from '@utils/static/removeUnusedFields';

const MobileImportTestStepCommandList = () => {
  const [query, setQuery] = useState('');

  const {
    watch,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<CreateTestStepData>();

  const selectedCaseIdx = watch('value');

  const mobileTestCaseListQuery = useMobileTestCaseListQuery();
  const mobileTestCaseList = mobileTestCaseListQuery.data;

  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');
  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const selectedOsCount =
    deviceInfo.os === DeviceInfoOs.Aos ? 'aosStepCount' : 'iosStepCount';

  const filteredMobileTestCaseList = mobileTestCaseList
    ?.map((mobileCase) => removeMobileUnusedFields(mobileCase, selectedOsCount))
    .filter((mobileCase) => filterSelectedIdx(mobileCase, idx));

  const filteredList =
    query === ''
      ? filteredMobileTestCaseList
      : filteredMobileTestCaseList?.filter((list) =>
          list.title.toLowerCase().includes(query.toLowerCase()),
        );

  const mobileTestStepListQuery = useMobileTestStepByCaseQuery(
    selectedCaseIdx,
    {
      enabled: selectedCaseIdx !== undefined,
    },
  );

  const mobileTestStep = mobileTestStepListQuery.data;

  const mobileTestStepByDevice = mobileTestStep?.[deviceInfo.os];
  const mobileTestStepListWithoutDisabled = mobileTestStepByDevice?.filter(
    (item) =>
      !(
        item.command.includes('import') ||
        (item.command === MobileCommandOptionServerName.IF &&
          item.value.includes('import'))
      ),
  );
  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckbox(mobileTestStepListWithoutDisabled, 'idx');
  const testStepHeader = [
    <Checkbox
      checked={selectedArray.length > 0}
      onChange={(e) => selectAllArray(e)}
      key="selected-checkbox"
    />,
    '#',
    'Command',
    'Description',
  ];

  const { uniqueCompareIdxArray } = useImportTestStep(
    mobileTestStepByDevice,
    selectedArray,
  );

  useEffect(() => {
    if (selectedCaseIdx && selectedArray.length === 0) {
      setError('optionsDto.stepIdxList', {
        type: 'manual',
        message: '테스트 스텝을 1개 이상 선택해 주세요.',
      });
    }
    if (query === '' || (selectedCaseIdx && selectedArray.length > 0)) {
      clearErrors('optionsDto.stepIdxList');
    }

    if (uniqueCompareIdxArray.length > 0) {
      const isReferencedStepIncluded = uniqueCompareIdxArray.every((item) =>
        selectedArray.includes(item),
      );
      if (!isReferencedStepIncluded) {
        setError('optionsDto.stepIdxList', {
          type: 'manual',
          message: '참조되는 Save Text 스텝을 같이 선택해 주세요.',
        });
      }
    }
  }, [selectedCaseIdx, selectedArray]);

  useEffect(() => {
    setValue('optionsDto.stepIdxList', selectedArray.join(','));
  }, [selectedArray]);

  useEffect(() => {
    return () => {
      clearErrors('optionsDto.stepIdxList');
      setValue('value', undefined);
    };
  }, [clearErrors, setValue]);

  return (
    <>
      <Controller
        control={control}
        name="value"
        rules={{ required: '테스트 케이스를 선택해 주세요.' }}
        render={({ field }) => (
          <ImportTestStepComboboxHeadlessInController
            placeholder="가져올 스텝의 테스트 케이스를 선택해 주세요."
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              resetSelectedArray();
            }}
            filteredList={filteredList}
            displayValue={(value: number) =>
              findTitleFromIdx(value, mobileTestCaseList) || query
            }
            isOptionDisabled={(value: ImportTestStepFilteredList) =>
              value.disabled
            }
            valueToSave={'idx'}
            valueToShow={'title'}
            setQuery={setQuery}
          />
        )}
      />
      {/*  Todo: 추후 모바일 생성 > 에러메시지 통일하게된다면 && 연산자 부분 삭제 */}
      {isWordInLocationPathname('web') && (
        <ErrorMessage
          name="value"
          errors={errors}
          render={({ message }) => (
            <p className="error-message !mt-2">{message}</p>
          )}
        />
      )}
      {selectedCaseIdx && (
        <section>
          <div className="border-b border-gray-300 px-1 py-1.5 text-sm font-medium text-gray-900">
            Select Test Step
          </div>
          <MobileImportTestStepTable
            query={mobileTestStepListQuery}
            headers={testStepHeader}
            rows={mobileTestStepByDevice}
            selectArray={selectArray}
            selectedArray={selectedArray}
          />
        </section>
      )}
      {/*  Todo: 추후 모바일 생성 > 에러메시지 통일하게된다면 && 연산자 부분 삭제 */}
      {isWordInLocationPathname('web') && (
        <ErrorMessage
          name="optionsDto.stepIdxList"
          errors={errors}
          render={({ message }) => (
            <p className="error-message !mt-2">{message}</p>
          )}
        />
      )}
    </>
  );
};

export default MobileImportTestStepCommandList;
