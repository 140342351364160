import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React from 'react';

const DefaultValue = () => {
  return (
    <div className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <span className="block truncate">Value</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronUpDownIcon
          className="size-5 text-gray-400"
          aria-hidden="true"
        />
      </span>
    </div>
  );
};

export default DefaultValue;
