import React, { Fragment, ReactNode } from 'react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { Button, ButtonProps } from '@autosquare/common';

type CommonDialogProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  iconType: 'caution' | 'checked' | 'yellow caution' | 'copy';
  title: string;
  subTitle: string;
  children: ReactNode;
  onClose?: () => void;
};

const CommonDialog = ({
  isOpen,
  setIsOpen,
  iconType,
  title,
  subTitle,
  children,
  onClose,
}: CommonDialogProps) => {
  const iconObject = {
    checked: <CheckIcon className="size-6 text-green-600" aria-hidden="true" />,
    caution: <ExclamationTriangleIcon className="size-6 text-red-500" />,
    'yellow caution': (
      <ExclamationTriangleIcon className="size-6 text-yellow-500" />
    ),
    copy: <DocumentDuplicateIcon className="size-6 text-white" />,
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        static
        onClose={() => {
          setIsOpen(false);
          onClose?.();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-300/75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-[60] overflow-y-auto scrollbar-thin">
          <div className="flex h-2/3 items-end justify-center p-4 text-center sm:min-h-full sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={clsx(
                  'relative z-[70] overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6',
                  subTitle?.length < 80 ? 'sm:max-w-lg' : 'sm:max-w-xl',
                )}
              >
                <div className="sm:flex sm:items-start">
                  <div
                    className={clsx(
                      'mx-auto flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:size-10',
                      {
                        'bg-green-100': iconType === 'checked',
                        'bg-red-100': iconType === 'caution',
                        'bg-yellow-100': iconType === 'yellow caution',
                        'bg-congress-blue': iconType === 'copy',
                      },
                    )}
                  >
                    {iconObject[iconType]}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                    <div className="mt-2">
                      <p
                        className={`whitespace-pre-wrap text-sm text-gray-500`}
                      >
                        {subTitle}
                      </p>
                    </div>
                  </div>
                </div>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

type DialogButtonPanelsProps = {
  children: ReactNode;
};

const DialogButtonPanels = ({ children }: DialogButtonPanelsProps) => {
  return (
    <div className="mt-5 flex items-center justify-center gap-6 sm:mt-4 sm:flex sm:flex-row-reverse sm:justify-start sm:gap-3">
      {children}
    </div>
  );
};

const DialogButton = ({ children, ...props }: ButtonProps) => {
  return <Button {...props}>{children}</Button>;
};

type DialogPanelsProps = DialogButtonPanelsProps;

const DialogPanels = ({ children }: DialogPanelsProps) => {
  return <div className="pt-4">{children}</div>;
};

interface DialogInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const DialogInput = ({ className, ...props }: DialogInputProps) => {
  return <input className={clsx('input-base', className)} {...props} />;
};

interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type DialogListboxProps = {
  value: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lists: (ListItem | any)[];
  buttonValue: string | number;
  valueToShow: string;
  valueToSave: string | number;
  disabled?: boolean;
  noListMessage?: string;
};

const DialogListbox = ({
  value,
  onChange,
  lists,
  buttonValue,
  valueToShow,
  valueToSave,
  disabled = false,
  noListMessage = 'Not Founded',
}: DialogListboxProps) => {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{buttonValue}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {lists?.length === 0 ? (
                  <li className="relative cursor-default select-none list-none px-4 py-2 text-gray-700">
                    {noListMessage}
                  </li>
                ) : (
                  lists?.map((value, index) => (
                    <ListboxOption
                      key={index}
                      className={clsx(
                        'group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                        value.disabled ? 'text-gray-400' : 'text-gray-900',
                      )}
                      value={value[valueToSave]}
                      disabled={value.disabled}
                    >
                      <span
                        className={
                          'block break-all font-normal group-data-[selected]:font-semibold'
                        }
                      >
                        {value[valueToShow]}
                      </span>
                      <span className="invisible absolute inset-y-0 right-0 flex items-center pr-4 group-data-[selected]:visible">
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

type DialogComboboxProps = {
  value: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredList: (string | number | any)[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  displayValue: (item: unknown) => string;
  valueToSave: string;
  valueToShow: string;
  reset?: () => void;
  placeholder?: string;
  isError?: boolean;
  error?: Error;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isOptionDisabled?: (item: any) => boolean;
};

const DialogCombobox = ({
  value,
  onChange,
  setQuery,
  displayValue,
  filteredList,
  valueToSave,
  valueToShow,
  reset,
  placeholder,
  isError,
  error,
  isOptionDisabled = () => false,
}: DialogComboboxProps) => {
  return (
    <Combobox as="div" value={value} onChange={onChange}>
      <div className="relative">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="relative flex w-full items-center justify-between">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholder}
              displayValue={displayValue}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
          {reset && (
            <button
              type="button"
              className="button-gray h-9 px-2 focus:outline-none"
              onClick={reset}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          )}
        </div>
        <ComboboxOptions
          className={clsx(
            'absolute z-10 mt-1 max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm',
            reset ? 'w-[calc(100%-2.5rem)]' : 'w-full',
          )}
        >
          {isError ? (
            <div className="relative cursor-default select-none px-4 py-2 text-red-600">
              {error.message}
            </div>
          ) : filteredList?.length === 0 ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
              Nothing found
            </div>
          ) : (
            filteredList?.map((filteredValue) => (
              <ComboboxOption
                key={filteredValue[valueToSave]}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[disabled]:opacity-50"
                value={filteredValue[valueToSave]}
                disabled={isOptionDisabled(filteredValue)}
              >
                <span className="break-all text-gray-900 group-data-[selected]:font-semibold group-data-[focus]:text-white">
                  {filteredValue[valueToShow]}
                </span>
                <span className="invisible absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[selected]:visible group-data-[focus]:text-white">
                  <CheckIcon className="size-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};

CommonDialog.DialogPanels = DialogPanels;
CommonDialog.DialogInput = DialogInput;
CommonDialog.DialogListbox = DialogListbox;
CommonDialog.DialogCombobox = DialogCombobox;
CommonDialog.DialogButtonPanels = DialogButtonPanels;
CommonDialog.DialogButton = DialogButton;

export default CommonDialog;
