import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';

import { webTestCaseKeys } from '@queries/ide/web/webTestCaseKeys';
import { webTestStepKeys } from '@queries/ide/web/webTestStepKeys';
import {
  CreateWebTestCaseData,
  WebTestCaseDetailData,
  UpdateWebTestCaseData,
} from 'src/types/testCase/type';
import { groupKeys } from '@queries/ide/groupKeys';

interface UpdateWebTestCaseStepOnDrop {
  step: number[];
}

interface CopyWebTestCaseMutation {
  idx: string;
  projectIdx: string;
}

// GET
const webTestCaseList = async (
  projectIdx: string,
): Promise<WebTestCaseDetailData[]> => {
  const response = await axiosInstance.get<WebTestCaseDetailData[]>(
    IDEAPI.GET_ALL_WEB_TEST_CASE + projectIdx,
  );
  return response.data;
};
export const useWebTestCaseListQuery = (
  options?: UseQueryOptions<WebTestCaseDetailData[], Error>,
) => {
  const { projectIdx } = useParams();
  return useQuery<WebTestCaseDetailData[], Error>({
    queryKey: webTestCaseKeys.list(projectIdx),
    queryFn: () => webTestCaseList(projectIdx),
    ...options,
  });
};

const webTestCaseDetail = async (
  idx: string,
): Promise<WebTestCaseDetailData> => {
  const response = await axiosInstance.get<WebTestCaseDetailData>(
    IDEAPI.GET_WEB_TEST_CASE_DETAIL + idx,
  );
  return response.data;
};
export const useWebTestCaseDetailQuery = (idx: string) =>
  useQuery<WebTestCaseDetailData, Error>({
    queryKey: webTestCaseKeys.detail(idx),
    queryFn: () => webTestCaseDetail(idx),
  });

// POST
const createWebTestCase = async (
  projectIdx: string,
  data: CreateWebTestCaseData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.CREATE_WEB_TEST_CASE + projectIdx,
    data,
  );
  return response.data;
};
export const useCreateWebTestCaseMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<unknown, Error, CreateWebTestCaseData>({
    mutationFn: (data) => createWebTestCase(projectIdx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(groupKeys.all);
      navigate(`/ide/projects/${projectIdx}/ui-test/web/test-case`);
    },
  });
};

const copyWebTestCase = async ({
  idx,
  projectIdx,
}: CopyWebTestCaseMutation): Promise<WebTestCaseDetailData> => {
  const response = await axiosInstance.post<WebTestCaseDetailData>(
    `${IDEAPI.COPY_WEB_TEST_CASE}${idx}`,
    { projectIdx: Number(projectIdx) },
  );
  return response.data;
};

export const useCopyWebTestCaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WebTestCaseDetailData, Error, CopyWebTestCaseMutation>({
    mutationFn: copyWebTestCase,
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.lists());
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};

// PUT
const updateWebTestCase = async (
  idx: string,
  data: UpdateWebTestCaseData,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.UPDATE_WEB_TEST_CASE_DETAIL + idx,
    data,
  );
  return response.data;
};
export const useUpdateWebTestCaseMutation = (idx: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, UpdateWebTestCaseData>({
    mutationFn: (data) => updateWebTestCase(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.all);
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};

const updateWebTestCaseStepOnDrop = async (
  idx: string,
  data: UpdateWebTestCaseStepOnDrop,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    IDEAPI.WEB_TEST_CASE_UPDATE_STEP + idx,
    data,
  );
  return response.data;
};
export const useUpdateWebTestCaseStepMutation = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const idx = searchParams.get('idx');

  return useMutation<unknown, Error, UpdateWebTestCaseStepOnDrop>({
    mutationFn: (data) => updateWebTestCaseStepOnDrop(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestStepKeys.all);
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};

const deleteWebTestCase = async (idxList: number[]): Promise<unknown> => {
  const response = await axiosInstance.delete<unknown>(
    IDEAPI.DELETE_WEB_TEST_CASE,
    { data: { idx_list: idxList } },
  );
  return response.data;
};
export const useDeleteWebTestCaseMutation = (idxList: number[]) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error>({
    mutationFn: () => deleteWebTestCase(idxList),
    onSuccess: () => {
      queryClient.invalidateQueries(webTestCaseKeys.lists());
      queryClient.invalidateQueries(groupKeys.all);
    },
  });
};
