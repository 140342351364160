import { ListBoxHeadlessInController } from '@autosquare/common';

import {
  findTextReplaceAddNameByServerName,
  textReplaceAddList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceAddList';

import {
  findTextReplaceFromNameByServerName,
  textReplaceFromList,
} from '@utils/static/mobileTestStep/textReplace/textReplaceFromList';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const TextReplaceFromAndAdd = () => {
  const { control, register } = useFormContext<CreateTestStepData>();

  return (
    <>
      <div className="grid grid-cols-5 items-center justify-between">
        <span className="col-span-1">From</span>
        <div className="col-span-4 w-full">
          <Controller
            control={control}
            name="optionsDto.textReplaceOptions.from"
            render={({ field }) => (
              <ListBoxHeadlessInController
                value={field.value}
                buttonValue={findTextReplaceFromNameByServerName(field.value)}
                onChange={field.onChange}
                lists={textReplaceFromList}
                valueToSave={'serverName'}
                valueToShow={'name'}
              />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-5 items-center justify-between">
        <span className="col-span-1">Add</span>
        <div className="col-span-4 grid w-full grid-cols-2 gap-5">
          <div className="col-span-1">
            <Controller
              control={control}
              name="optionsDto.textReplaceOptions.addKey"
              render={({ field }) => (
                <ListBoxHeadlessInController
                  value={field.value}
                  buttonValue={findTextReplaceAddNameByServerName(field.value)}
                  onChange={field.onChange}
                  lists={textReplaceAddList}
                  valueToSave={'serverName'}
                  valueToShow={'name'}
                />
              )}
            />
          </div>
          <div className="col-span-1">
            <input
              type="number"
              className="input-base"
              {...register('optionsDto.textReplaceOptions.addValue', {
                required: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                min: {
                  value: 0,
                  message: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                },
                max: {
                  value: 50,
                  message: '최소 0 이상 최대 50 이하 값을 입력해 주세요.',
                },
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TextReplaceFromAndAdd;
