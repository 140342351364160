import { useEffect, useState, useRef } from 'react';

export const useImageSize = (image: HTMLImageElement, base64Image: string) => {
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const divWidth = divRef.current?.clientWidth;

    if (divWidth) {
      const imageObj = new window.Image();
      imageObj.src = `data:image/png;base64,${base64Image}`;

      imageObj.onload = () => {
        setImageWidth(
          imageObj.width / 3 < divWidth ? imageObj.width / 3 : divWidth,
        );
        setImageHeight(
          imageObj.width / 3 < divWidth
            ? imageObj.height / 3
            : imageObj.height * (divWidth / imageObj.width),
        );
      };
    }
  }, [image, base64Image]);

  return { imageWidth, imageHeight, divRef };
};
