import React from 'react';

import {
  DeviceKeyServerName,
  findDeviceKeyFromServerName,
} from '@utils/static/deviceKeyList';
import {
  MobileCommandOptionServerName,
  findMobileCommandNameFromServerName,
} from '@utils/static/mobileCommandOption';

import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

import DivisionWord from '@components/shared/TestStepDetail/DivisionWord';
import TestStepDetailBasicDefinition from '@components/shared/TestStepDetail/TestStepDetailBasicDefinition';
import DetailDivisionLine from '@components/shared/TestStepDetail/DetailDivisionLine';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import TestStepListValue from '@components/TestStepCommon/TestStepListValue';

interface Props {
  mobileTestStepData: MobileTestStepData;
}

const BasicIfCommand = ({ mobileTestStepData }: Props) => {
  const passCommand = mobileTestStepData?.value.split(',')[0];
  const failCommand = mobileTestStepData?.value.split(',')[1];

  const mobileTestCaseQuery = useMobileTestCaseListQuery();
  const mobileTestCaseList = mobileTestCaseQuery.data;

  const findTitleFromIdx = (idx: number) =>
    mobileTestCaseList?.find((testCase) => testCase.idx === idx)?.title;

  return (
    <>
      <DetailDivisionLine
        title={'Condition'}
        description={'조건식을 설정합니다.'}
      />
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findMobileCommandNameFromServerName(
          mobileTestStepData?.optionsDto.conditionCommand,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value'}
        description={
          mobileTestStepData?.optionsDto.conditionCommand ===
          MobileCommandOptionServerName.CompareImage ? (
            <TestStepListValue
              imageValue={mobileTestStepData?.optionsDto.conditionValue}
            />
          ) : (
            mobileTestStepData?.optionsDto.conditionValue
          )
        }
      />
      <DetailDivisionLine
        title={'Action'}
        description={'조건식의 결과에 따라 실행될 동작을 지정합니다.'}
      />
      <DivisionWord color="green">PASS</DivisionWord>
      <TestStepDetailBasicDefinition
        term={'Command'}
        description={findMobileCommandNameFromServerName(
          passCommand as MobileCommandOptionServerName,
        )}
      />
      <TestStepDetailBasicDefinition
        term={'Value'}
        description={
          passCommand === MobileCommandOptionServerName.DeviceKey
            ? findDeviceKeyFromServerName(
                mobileTestStepData?.optionsDto
                  .passActionValue as DeviceKeyServerName,
              )?.listName
            : passCommand === MobileCommandOptionServerName.ImportTestCase
              ? findTitleFromIdx(
                  Number(mobileTestStepData?.optionsDto.passActionValue),
                )
              : mobileTestStepData?.optionsDto.passActionValue
        }
      />
      {failCommand !== undefined &&
        mobileTestStepData?.optionsDto?.failActionValue !== undefined && (
          <>
            <DivisionWord color="red">FAIL</DivisionWord>
            <TestStepDetailBasicDefinition
              term={'Command'}
              description={findMobileCommandNameFromServerName(
                failCommand as MobileCommandOptionServerName,
              )}
            />
            <TestStepDetailBasicDefinition
              term={'Value'}
              description={
                failCommand === MobileCommandOptionServerName.DeviceKey
                  ? findDeviceKeyFromServerName(
                      mobileTestStepData?.optionsDto
                        .failActionValue as DeviceKeyServerName,
                    )?.listName
                  : failCommand === MobileCommandOptionServerName.ImportTestCase
                    ? findTitleFromIdx(
                        Number(mobileTestStepData?.optionsDto.failActionValue),
                      )
                    : mobileTestStepData?.optionsDto.failActionValue
              }
            />
          </>
        )}
    </>
  );
};

export default BasicIfCommand;
