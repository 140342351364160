export const getCroppedImage = (
  x: number,
  y: number,
  width: number,
  height: number,
  screenshot: string,
) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  const imageObj = new window.Image();
  imageObj.src = `data:image/png;base64,${screenshot}`;
  context.drawImage(imageObj, x, y, width, height, 0, 0, width, height);
  return canvas.toDataURL();
};
