import { RootState } from '@app/store';
import AddDevice from '@components/AddDevice/AddDevice';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import {
  ArrowTopRightOnSquareIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { hasDownloadedData } from '@store/ide/settings/settingsSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  refresh?: () => void;
};

const AddWirelessDeviceButton = ({ refresh }: Props) => {
  const [isWirelessDeviceModal, setIsWirelessDeviceModal] = useState(false);
  const [connectionType, setConnectionType] = useState<'wifi' | 'port'>('wifi');

  const dispatch = useDispatch();
  const isDownloadedData = useSelector(
    (state: RootState) => state.settings.isDownloadedData,
  );

  useEffect(() => {
    if (isDownloadedData) {
      refresh && refresh();
      dispatch(hasDownloadedData(false));
    }
  }, [isDownloadedData]);

  const menuItems = [
    {
      idx: 1,
      title: 'Wi-Fi ADB 연결',
      device: 'Android',
      onClick: () => {
        setIsWirelessDeviceModal(true);
        setConnectionType('wifi');
      },
    },
    {
      idx: 2,
      title: 'ADB 포트 입력',
      device: 'Android',
      onClick: () => {
        setIsWirelessDeviceModal(true);
        setConnectionType('port');
      },
    },
    {
      idx: 3,
      title: 'Xcode 연결',
      device: 'iOS',
      onClick: () => {
        window.electron.send(
          'openExternalPage',
          'docs/ide/getting-started-with-ide-mac/#i-os',
        );
      },
    },
  ];

  return (
    <section>
      <Menu>
        <MenuButton>
          <div className="flex items-center justify-between gap-1.5 rounded-md bg-gray-500 px-2.5 py-1.5">
            <PlusCircleIcon
              className="size-5 font-semibold text-white"
              strokeWidth={2.25}
            />
            <p className="text-sm font-semibold leading-tight text-white">
              Add Wireless Device
            </p>
          </div>
        </MenuButton>
        <MenuItems
          anchor="bottom"
          className="flex w-[11.5rem] flex-col items-start justify-start gap-2 rounded-md bg-white p-1 shadow"
        >
          {menuItems.map((item) => (
            <MenuItem key={item.idx}>
              <button
                type="button"
                className="group flex size-full items-center justify-between rounded-md px-2 py-0.5 text-start text-sm font-normal leading-normal text-gray-900 data-[focus]:bg-indigo-600"
                onClick={item.onClick}
              >
                <span className="group-data-[focus]:text-white">
                  {item.title}
                </span>
                <div className="flex items-center gap-1 text-right text-xs font-medium leading-normal text-gray-500 group-data-[focus]:text-gray-200">
                  <span>{item.device}</span>
                  {item.device === 'iOS' && (
                    <ArrowTopRightOnSquareIcon className="mb-0.5 size-3.5" />
                  )}
                </div>
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Menu>
      {isWirelessDeviceModal && (
        <AddDevice
          setState={setIsWirelessDeviceModal}
          connectionType={connectionType}
        />
      )}
    </section>
  );
};

export default AddWirelessDeviceButton;
