import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { useEffect } from 'react';

const useSetupCoordinates = (
  command: MobileCommandOptionServerName,
  clickBy: ClickId,
  value: string,
  conditionCommand: MobileCommandOptionServerName,
) => {
  const { setStartX, setStartY, setEndX, setEndY, imageWidth, imageHeight } =
    useMobileTestStepContext();

  useEffect(() => {
    if (
      command === MobileCommandOptionServerName.Click &&
      clickBy === ClickId.Coordinate
    ) {
      setStartX(Math.floor(imageWidth / 2));
      setStartY(Math.floor(imageHeight / 2));
    }

    if (
      (command === MobileCommandOptionServerName.Click &&
        clickBy === ClickId.Image) ||
      command === MobileCommandOptionServerName.CompareImage ||
      (command === MobileCommandOptionServerName.IF &&
        conditionCommand === MobileCommandOptionServerName.CompareImage)
    ) {
      setStartX(Math.floor(imageWidth / 2 - 150));
      setStartY(Math.floor(imageHeight / 2 - 150));
      setEndX(Math.floor(imageWidth / 2 + 150));
      setEndY(Math.floor(imageHeight / 2 + 150));
    }
  }, [command, clickBy, conditionCommand, imageWidth, imageHeight]);

  const scrollSwipeSetCoordinateValues = () => {
    setStartX(Math.floor(imageWidth / 2));
    setStartY(Math.floor((imageHeight / 3) * 2));
    setEndX(Math.floor(imageWidth / 2));
    setEndY(Math.floor(imageHeight / 3));
  };

  useEffect(() => {
    if (command === MobileCommandOptionServerName.ScrollSwipe) {
      scrollSwipeSetCoordinateValues();
    }
  }, [imageWidth, imageHeight, command]);

  useEffect(() => {
    if (
      command === MobileCommandOptionServerName.Loop &&
      value === MobileCommandOptionServerName.ScrollSwipe
    ) {
      scrollSwipeSetCoordinateValues();
    }
  }, [imageWidth, imageHeight, command, value]);
};

export default useSetupCoordinates;
