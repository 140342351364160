import { Button } from '@autosquare/common/src/components/shared/Button/Button';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

interface Props {
  selectedArray?: unknown[];
  children: ReactNode;
  openDialog: () => void;
  hasCopied?: boolean;
  copyOnClick?: () => void;
}

export const TableWrapper = ({
  selectedArray,
  openDialog,
  children,
  copyOnClick,
  hasCopied = false,
}: Props) => {
  return (
    <div className="mt-6 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {selectedArray.length > 0 && (
            <div className="relative">
              <div
                className={clsx(
                  'div-delete-all',
                  hasCopied ? 'ml-2 2xl:ml-6' : 'left-14 ml-8 2xl:left-20',
                )}
              >
                {hasCopied && (
                  <Button
                    type="button"
                    variant="primary"
                    buttonSize="xs"
                    onClick={copyOnClick}
                  >
                    Copy all
                  </Button>
                )}
                <Button
                  type="button"
                  variant="secondary"
                  buttonSize="xs"
                  onClick={openDialog}
                >
                  Delete all
                </Button>
              </div>
            </div>
          )}
          <table className="h-full min-w-full divide-y divide-gray-300">
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};
