import {
  Button,
  CaseExport,
  CaseImport,
  CreatePlusButton,
  DistributedExecutionButton,
  DoubleCheckDialogHeadlessUI,
  ErrorMessage,
  GroupManagement,
  PageHeadingButtons,
  PagingHeadingsNavigate,
  TableSkeleton,
  useCheckbox,
} from '@autosquare/common';

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useDeleteTestCaseMutation,
  useMobileTestCaseListQuery,
} from '@lib/api/ide/mobile/mobileTestCase';

import MobileTestCaseList from './components/MobileTestCaseList';
import GroupContainer from '@components/shared/TestCaseGroup/GroupContainer';
import GroupProvider from '@contexts/ide/GroupProvider';
import TestExecutionContainer from '@components/shared/TestExecution/TestExecutionContainer';

const MobileTestCase = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const {
    data: mobileTestCaseList,
    isLoading,
    isFetching,
    isError,
    error,
  } = useMobileTestCaseListQuery();

  const {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  } = useCheckbox(mobileTestCaseList, 'idx');

  const deleteTestCaseMutation = useDeleteTestCaseMutation();

  const [isGroupOpened, setIsGroupOpened] = useState(false);
  const [isDistributedExecution, setIsDistributedExecution] = useState(false);

  const breadcrumbs = [
    {
      title: 'UI Test',
      href: pathname.split('/').slice(0, -2).join('/') + '/overview',
    },
    { title: 'Mobile', href: pathname + search },
    { title: 'Test Case', href: pathname + search },
    { title: 'List', href: pathname + search },
  ];

  const pageHeadingButtons: PageHeadingButtons[] = [
    {
      icon: <CreatePlusButton />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('create');
      },
      description: 'Create Case',
    },
    {
      icon: <DistributedExecutionButton />,
      variant: 'imageBasic',
      onClick: () => {
        setIsDistributedExecution(true);
      },
      description: 'Distributed Execution',
    },
    {
      icon: <GroupManagement />,
      variant: 'imageBasic',
      onClick: () => {
        setIsGroupOpened(true);
      },
      description: 'Group Management',
    },
    {
      icon: <CaseExport />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('export');
      },
      description: 'Case Export',
    },
    {
      icon: <CaseImport />,
      variant: 'imageBasic',
      onClick: () => {
        navigate('import');
      },
      description: 'Case Import',
    },
  ];

  return (
    <>
      <PagingHeadingsNavigate
        breadcrumbs={breadcrumbs}
        pageTitle={'Test Case List'}
        pageHeadingButtons={pageHeadingButtons}
      />
      {isLoading || isFetching ? (
        <TableSkeleton />
      ) : isError ? (
        <ErrorMessage>{error.message}</ErrorMessage>
      ) : (
        <GroupProvider>
          <MobileTestCaseList
            mobileTestCaseList={mobileTestCaseList}
            selectAllArray={selectAllArray}
            selectArray={selectArray}
            selectedArray={selectedArray}
            openDialog={openDialog}
          />
        </GroupProvider>
      )}
      <DoubleCheckDialogHeadlessUI
        isOpened={isOpenedCautionDialog}
        setIsOpened={setIsOpenedCautionDialog}
        type="caution"
        title={deleteTestCaseMutation.isError ? 'Error' : 'Delete Test Case'}
        subTitle={
          deleteTestCaseMutation.isError
            ? deleteTestCaseMutation.error.message
            : '해당 테스트 케이스를 삭제하시겠습니까?'
        }
        buttonChildren={
          <>
            {!deleteTestCaseMutation.isError && (
              <Button
                type="button"
                variant="caution"
                onClick={() =>
                  deleteTestCaseMutation.mutate(
                    {
                      idx_list: selectedArray,
                    },
                    {
                      onSuccess: () => {
                        closeDialog();
                        resetSelectedArray();
                      },
                    },
                  )
                }
              >
                Delete
              </Button>
            )}
            <Button type="button" variant="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </>
        }
      />
      <GroupProvider>
        {isGroupOpened && <GroupContainer closeModal={setIsGroupOpened} />}
      </GroupProvider>
      {isDistributedExecution && (
        <TestExecutionContainer
          setState={setIsDistributedExecution}
          testType={'case'}
          deviceType={'mobile'}
          isDistributedClicked={true}
        />
      )}
    </>
  );
};

export default MobileTestCase;
