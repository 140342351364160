import { useGroupContext } from '@contexts/ide/GroupProvider';
import { useGetTestCaseServiceGroupListQuery } from '@lib/api/ide/serviceGroup/serviceGroup';
import { useTestCaseGroupListQuery } from '@lib/api/ide/testGroup/testCaseGroup';
import React from 'react';

const GroupTab = () => {
  const {
    isTestGroupClicked,
    setIsTestGroupClicked,
    isServiceGroupClicked,
    setIsServiceGroupClicked,
    tabGroupType,
    setTabGroupType,
  } = useGroupContext();

  const testGroupListQuery = useTestCaseGroupListQuery({
    enabled: tabGroupType === 'Test',
  });
  const testGroupList = testGroupListQuery?.data;
  const serviceGroupListQuery = useGetTestCaseServiceGroupListQuery({
    enabled: tabGroupType === 'Service',
  });
  const serviceGroupList = serviceGroupListQuery?.data;

  const tabItem = [
    {
      name: 'Test Group',
      onClick: () => {
        setTabGroupType('Test');
        setIsTestGroupClicked(true);
        setIsServiceGroupClicked(false);
      },
      css: isTestGroupClicked
        ? 'text-blue-900 border-blue-900'
        : 'text-gray-600',
      underBar: isTestGroupClicked && (
        <div className="w-30 rounded-md border-2 border-blue-900"></div>
      ),
    },
    {
      name: 'Service Group',
      onClick: () => {
        setTabGroupType('Service');
        setIsServiceGroupClicked(true);
        setIsTestGroupClicked(false);
      },
      css: isServiceGroupClicked
        ? 'text-blue-900 border-blue-900'
        : 'text-gray-600',
      underBar: isServiceGroupClicked && (
        <div className="w-30 rounded-md border-2 border-blue-900"></div>
      ),
    },
  ];

  return (
    <div className="mb-5 inline-flex h-12 w-full items-center justify-start gap-[60px] border-b border-gray-200 px-8 py-2">
      {tabItem.map((item) => (
        <div
          className="flex-col items-center justify-center gap-1 self-stretch rounded-md py-1.5"
          key={item.name}
        >
          <div className="mb-3 flex gap-2">
            <button
              type="button"
              className={`whitespace-nowrap text-center text-sm font-semibold leading-tight  ${item.css}`}
              onClick={item.onClick}
            >
              {item.name}
            </button>
            <div className="flex items-center justify-start gap-2.5 rounded-2xl bg-gray-100">
              <div className="h-5 w-[30px] pt-px text-center text-sm font-semibold leading-tight text-gray-900">
                {item.name === 'Test Group'
                  ? testGroupList?.length
                  : serviceGroupList?.length}
              </div>
            </div>
          </div>
          {item.underBar}
        </div>
      ))}
    </div>
  );
};

export default GroupTab;
