import { useMobileTestStepBySaveTextQuery } from '@lib/api/ide/mobile/mobileTestStep';

import InputRadio from '@components/shared/TestStepDetail/InputRadio';
import Textarea from '@components/shared/TestStepDetail/Textarea';
import SecretEditBox from '@components/shared/TestStep/SecretEditBox';

import {
  CompareBy,
  CompareRange,
  MobileTestStepData,
  UpdateTestStepData,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';

import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

import { useSpeedMobileTestStepBySaveTextQuery } from '@lib/api/ide/speedMobile/speedMobileTestStep';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

import TestStepSelectSaveText from './TestStepConfirmOrInputText/TestStepSelectSaveText';
import From from '../ClickTextReplace/From';
import Add from '../ClickTextReplace/Add';
import DateFormat from '../ClickTextReplace/DateFormat';

interface Props {
  mobileTestStep: MobileTestStepData;
}

const TestStepConfirmOrInputText = ({ mobileTestStep }: Props) => {
  const [searchParams] = useSearchParams();
  const testCaseIdx = Number(searchParams.get('idx'));

  const uiLocation = isWordInLocationPathname('ui-test');
  const mobileTestStepBySaveTextQuery = uiLocation
    ? useMobileTestStepBySaveTextQuery(testCaseIdx)
    : useSpeedMobileTestStepBySaveTextQuery(testCaseIdx);

  const saveTextLists = (
    mobileTestStepBySaveTextQuery.data as MobileTestStepData[]
  )?.filter(
    (saveText: MobileTestStepData) =>
      saveText.mobileOs === mobileTestStep.mobileOs,
  ) as MobileTestStepData[];

  const {
    watch,
    setValue,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext<UpdateTestStepData>();
  const compareBy = watch('optionsDto.compareBy');

  const radioDefaultOptions = [
    {
      id: 'id',
      title: '테스트 스텝 선택',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Id,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.textReplaceOptions', undefined);
      },
    },
    {
      id: 'text',
      title: '직접 입력',
      defaultChecked: mobileTestStep.optionsDto.compareBy === CompareBy.Text,
      onClick: () => {
        setValue(
          'optionsDto.compareIdx',
          mobileTestStep.optionsDto.compareBy === CompareBy.Id
            ? mobileTestStep.optionsDto.compareIdx
            : undefined,
        );
        setValue(
          'optionsDto.compareText',
          mobileTestStep.optionsDto.compareBy === CompareBy.Text
            ? mobileTestStep.optionsDto.compareText
            : undefined,
        );
        setValue('optionsDto.textReplace', undefined);
        setValue('optionsDto.textReplaceOptions', undefined);
      },
    },
  ];
  const radioWithTextReplace = {
    id: 'replace',
    title: 'Text Replace',
    defaultChecked: mobileTestStep.optionsDto.textReplace === 'true',
    onClick: () => {
      setValue('optionsDto.textReplace', 'true');
      setValue('optionsDto.compareText', undefined);
      setValue('optionsDto.compareRange', CompareRange.Equal);
      setValue('optionsDto.compareIdx', undefined);
    },
  };

  const radioOptions =
    mobileTestStep.command === MobileCommandOptionServerName.ConfirmText &&
    uiLocation
      ? [...radioDefaultOptions, radioWithTextReplace]
      : radioDefaultOptions;

  const equalAndContainRadioOptions = [
    {
      title: '동일',
      id: 'equal',
      defaultChecked:
        mobileTestStep.optionsDto.compareRange === CompareRange.Equal,
    },
    {
      title: '포함',
      id: 'contain',
      defaultChecked:
        mobileTestStep.optionsDto.compareRange === CompareRange.Contain,
    },
  ];

  const isSecret = watch('optionsDto.textEncrypt') === 'true' ? true : false;
  const [hasValue] = useState(
    mobileTestStep.optionsDto.compareBy === CompareBy.Text,
  );

  useEffect(() => {
    if (
      mobileTestStep.optionsDto.compareBy === CompareBy.Text &&
      mobileTestStep.optionsDto.textEncrypt === 'true'
    ) {
      setValue('optionsDto.compareText', null);
    }
  }, []);

  useEffect(() => {
    if (compareBy === CompareBy.Text) {
      clearErrors('optionsDto.compareIdx');
    }
  }, [compareBy, clearErrors]);

  return (
    <>
      <div className="items-center border-b border-gray-200 sm:flex sm:px-6 sm:py-4">
        <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48 ">
          Value*
        </dt>
        <dd className="mt-1 grid w-full grid-rows-1 gap-2 text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <Textarea name={'value'} />
          <div className="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-10 sm:space-y-0">
            <InputRadio
              name={'optionsDto.compareBy'}
              radioOptions={radioOptions}
            />
            {mobileTestStep.command ===
              MobileCommandOptionServerName.InputText &&
              compareBy === CompareBy.Text && (
                <SecretEditBox control={control} />
              )}
          </div>
          {compareBy === CompareBy.Id && saveTextLists?.length === 0 ? (
            <div className="flex flex-col items-center justify-center border border-solid border-border-line p-3 text-sm">
              <p className="mb-2">선택 가능한 테스트 스텝이 없습니다.</p>
              <p>Save Text Step을 먼저 추가해주세요.</p>
            </div>
          ) : compareBy === CompareBy.Id && saveTextLists?.length !== 0 ? (
            <TestStepSelectSaveText saveTextLists={saveTextLists} />
          ) : (
            compareBy === CompareBy.Text && (
              <>
                <input
                  type={'text'}
                  className={clsx('input-base', {
                    'password-input': isSecret,
                  })}
                  placeholder={
                    mobileTestStep.command ==
                    MobileCommandOptionServerName.ConfirmText
                      ? '비교할 텍스트를 직접 입력해주세요.'
                      : mobileTestStep.command ==
                            MobileCommandOptionServerName.InputText &&
                          isSecret &&
                          hasValue
                        ? '암호화된 입력 값은 표시되지 않습니다.'
                        : '텍스트를 입력해주세요.'
                  }
                  {...register('optionsDto.compareText', {
                    required: {
                      value:
                        mobileTestStep.optionsDto.compareBy ===
                          CompareBy.Text && !isSecret,
                      message: 'Value값을 입력해 주세요.',
                    },
                  })}
                />
                <ErrorMessage
                  name={'optionsDto.compareText'}
                  errors={errors}
                  render={({ message }) => (
                    <p className="standard-error-message">{message}</p>
                  )}
                />
              </>
            )
          )}
          {mobileTestStep.command ===
            MobileCommandOptionServerName.ConfirmText &&
            compareBy !== CompareBy.Replace && (
              <InputRadio
                name={'optionsDto.compareRange'}
                radioOptions={equalAndContainRadioOptions}
              />
            )}
        </dd>
      </div>
      {compareBy === CompareBy.Replace && (
        <>
          <From from={mobileTestStep?.optionsDto?.textReplaceOptions?.from} />
          <Add
            addKey={mobileTestStep?.optionsDto?.textReplaceOptions?.addKey}
            addValue={mobileTestStep?.optionsDto?.textReplaceOptions?.addValue}
          />
          <DateFormat
            textReplaceOptions={mobileTestStep?.optionsDto?.textReplaceOptions}
          />
        </>
      )}
    </>
  );
};

export default TestStepConfirmOrInputText;
