import { MobileTestStepDataOptionsDto } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { ClickId } from '../clickOptions';
import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '../deviceKeyList';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '../mobileCommandOption';
import { findTitleFromIdx } from '../findTitleFromIdx';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';

export const getStepPropertyValue = (
  commandText: MobileCommandOptionServerName,
  valueText: string,
  optionsDto: MobileTestStepDataOptionsDto,
  saveTextIndex: number,
) => {
  const isUiTest = isWordInLocationPathname('ui-test');
  const isSpeedTest = isWordInLocationPathname('speed-test');

  const { data: mobileTestCaseList } = useMobileTestCaseListQuery({
    enabled:
      isUiTest && commandText === MobileCommandOptionServerName.ImportTestCase,
  });

  const { data: mobileSpeedTestCaseList } = useSpeedMobileTestCaseListQuery({
    enabled:
      isSpeedTest &&
      commandText === MobileCommandOptionServerName.ImportTestCase,
  });
  const testCaseList = mobileTestCaseList || mobileSpeedTestCaseList;
  const testCaseTitle = findTitleFromIdx(Number(valueText), testCaseList);
  const importTestStepCount = optionsDto?.stepIdxList?.split(',').length;

  switch (commandText) {
    case MobileCommandOptionServerName.ScrollSwipe:
      return `[${valueText}]`;

    case MobileCommandOptionServerName.Click:
      if (
        valueText?.split(',').length >= 2 &&
        optionsDto.clickBy === ClickId.Coordinate
      ) {
        return `[${valueText}]`;
      } else {
        return valueText;
      }

    case MobileCommandOptionServerName.DeviceKey:
      return findListNameFromServerName(valueText as DeviceKeyServerName);

    case MobileCommandOptionServerName.Loop:
      return findMobileCommandNameFromServerName(
        valueText as MobileCommandOptionServerName,
      );

    case MobileCommandOptionServerName.ConfirmText:
    case MobileCommandOptionServerName.InputText:
      if (optionsDto.compareBy === 'text') {
        if (optionsDto.textEncrypt === 'true') {
          return `${valueText} \ntarget text="**********"`;
        } else if (optionsDto.textEncrypt === 'false') {
          return `${valueText} \ntarget text="${optionsDto.compareText}"`;
        }
      } else if (optionsDto.compareBy === 'id') {
        return `${valueText} \ntarget text=#${saveTextIndex}`;
      } else {
        return valueText;
      }
      break;

    case MobileCommandOptionServerName.ImportTestCase:
      return testCaseTitle;

    case MobileCommandOptionServerName.ImportTestStep:
      return `${testCaseTitle} / ${importTestStepCount}`;

    default:
      return valueText;
  }
};
