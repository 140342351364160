import { CreateWebTestStepData } from '@customTypes/testStep/type';
import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const TestStepDescription = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateWebTestStepData>();

  return (
    <>
      <div className="w-full">
        <label htmlFor="url" className="sr-only">
          Description
        </label>
        <input
          type="text"
          name="description"
          id="description"
          className="input-base"
          placeholder="Description"
          {...register('description', {
            required: 'Description을 입력해 주세요.',
            maxLength: { value: 200, message: '200자 이하 입력해 주세요' },
          })}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name="description"
        render={({ message }) => (
          <p className="error-message !mt-2">{message}</p>
        )}
      />
    </>
  );
};

export default TestStepDescription;
