import { Menu, MenuButton } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { GroupContextMenu } from './GroupContextMenu';
import { TestCaseGroup } from '@customTypes/testCaseGroup/type';

type Props = {
  item: TestCaseGroup;
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
  setGroupIdx: React.Dispatch<React.SetStateAction<number>>;
};
const GroupContentCard = ({ item, setOpenCaution, setGroupIdx }: Props) => {
  const tableHeader = [
    { idx: 1, title: '' },
    { idx: 2, title: 'Mobile' },
    { idx: 3, title: 'Web' },
    { idx: 4, title: 'Case Count' },
  ];

  const tableBody = [
    {
      idx: 1,
      testType: 'UI Test',
      mobile: item.mobileCaseCount,
      web: item.webCaseCount,
      caseCount: item.totalCaseCount,
    },
    {
      idx: 2,
      testType: 'Speed Test',
      mobile: item.mobileSpeedCaseCount,
      web: item.webSpeedCaseCount,
      caseCount: item.totalSpeedCaseCount,
    },
  ];

  return (
    <li className="overflow-hidden rounded-xl border border-gray-200">
      <div className="flex items-center justify-between rounded-t-md border-b bg-gray-50 px-6 py-3 text-gray-800">
        <h5 className="flex items-center gap-x-4 truncate text-base font-medium">
          {item.name}
        </h5>
        <Menu as="div" className="relative">
          <MenuButton
            className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500"
            onClick={() => setGroupIdx(item.idx)}
          >
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="size-6" aria-hidden="true" />
          </MenuButton>
          <GroupContextMenu setOpenCaution={setOpenCaution} />
        </Menu>
      </div>
      <div className="w-full px-2.5">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              {tableHeader.map((header) => (
                <th
                  key={header.idx}
                  className="py-2 text-center text-sm font-medium leading-normal text-gray-900"
                >
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((body) => (
              <tr
                key={body.idx}
                className="border-t border-solid border-gray-100"
              >
                <td className="py-2 text-sm font-medium leading-normal text-gray-900">
                  {body.testType}
                </td>
                <td className="py-2 text-center text-sm font-normal leading-normal text-gray-900">
                  {body.mobile}
                </td>
                <td className="py-2 text-center text-sm font-normal leading-normal text-gray-900">
                  {body.web}
                </td>
                <td className="py-2 text-center text-sm font-normal leading-normal text-gray-900">
                  {body.caseCount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </li>
  );
};

export default GroupContentCard;
