window.onstorage = (e: StorageEvent) => {
  if (e.key === 'applicationUrl') {
    return;
  }
};

const applicationUrl = localStorage.getItem('applicationUrl');
const isIdeLogin = location.pathname.includes('/ide/login');
const isNotLocalhost = !location.origin.includes('http://localhost:3002');
const isNotApplicationUrl = location.origin !== applicationUrl;

if (
  isIdeLogin &&
  (!applicationUrl || (isNotLocalhost && isNotApplicationUrl))
) {
  setTimeout(() => {
    localStorage.setItem('applicationUrl', location.origin);
    location.reload();
  }, 1000);
}

const BASE_URL = `${applicationUrl}/api/`;

const LOCAL_URL = 'http://localhost:8081/api/';

const PROJECT = `${BASE_URL}project`;
const SCHEDULER = `${BASE_URL}scheduler`;
const LOCAL_SCHEDULER = `${LOCAL_URL}scheduler`;

// MOBILE
const MOBILE_TEST_CASE = `${BASE_URL}testcase`;
const MOBILE_TEST_SCENARIO = `${BASE_URL}scenario`;
const MOBILE_DEVICE = `${LOCAL_URL}device/mobile`;
const MOBILE_TEST_STEP = `${BASE_URL}teststep`;
const MOBILE_TEST_RESULT = `${BASE_URL}result`;

const LOCAL_MOBILE_TEST_CASE = `${LOCAL_URL}testcase`;
const LOCAL_MOBILE_EXECUTION = `${LOCAL_URL}execute/mobile`;

// Mobile Speed
const MOBILE_WEB_SPEED_TEST_STEP = `${MOBILE_TEST_STEP}/speed/web`;
const MOBILE_SPEED_TEST_RESULT = `${BASE_URL}result/speed/mobile`;
const MOBILE_SPEED_TEST_STEP = `${BASE_URL}teststep/speed/app`;
const MOBILE_SPEED_TEST_SCENARIO = `${BASE_URL}scenario/speed`;

// WEB
const WEB_TEST_CASE = `${BASE_URL}web/testcase`;
const WEB_TEST_STEP = `${BASE_URL}web/teststep`;
const WEB_TEST_STEPS = `${BASE_URL}web/teststeps`;
const WEB_TEST_SCENARIO = `${BASE_URL}web/scenario`;
const WEB_TEST_RESULT = `${BASE_URL}web/result/functional`;

// WEB SPEED
const WEB_SPEED_TEST_CASE = `${BASE_URL}testcase/speed/web`;
const WEB_SPEED_TEST_STEP = `${BASE_URL}web/teststep/speed`;
const WEB_SPEED_TEST_RESULT = `${BASE_URL}result/speed/list`;
const WEB_SPEED_TEST_RESULT_DETAIL = `${BASE_URL}result/speed/list/detail`;
const WEB_SPEED_TEST_SCENARIO = `${BASE_URL}web/speed/scenario`;

const LOCAL_WEB_EXECUTION = `${LOCAL_URL}execute/web`;

// SETTING
const SETTINGS = `${BASE_URL}settings`;
const LOCAL_SETTINGS = `${LOCAL_URL}settings`;
const WEB_DRIVER_AGENT_CONTROLLER = `${BASE_URL}wda`;

export const IDEAPI = {
  // Overview
  OVERVIEW: `${BASE_URL}overview?projectIdx=`,
  MOBILE_OVERVIEW: `${BASE_URL}overview/mobile?projectIdx=`,
  WEB_OVERVIEW: `${BASE_URL}overview/web?projectIdx=`,

  LOGIN: `${BASE_URL}user/login`,
  USER_SETTING: `${LOCAL_URL}settings/user`,
  APPLICATION: `${BASE_URL}settings`,
  USER: `${BASE_URL}user`,

  // User
  GET_USER_INFO: `${BASE_URL}userinfo`,

  // Settings
  POST_APP_URL: `${LOCAL_SETTINGS}/application`,
  EMAIL_ALARM_SETTING: `${SETTINGS}/alarm`,
  TEST_DEVICE_SETTING: `${SETTINGS}/device`,

  // Web-Driver-Agent-Controller
  UPLOAD_WDA: `${WEB_DRIVER_AGENT_CONTROLLER}/upload/`,
  DOWNLOAD_WDA: `${WEB_DRIVER_AGENT_CONTROLLER}/download-list`,

  // Mobile Device
  GET_DEVICE_LIST: `${MOBILE_DEVICE}`,
  CREATE_SESSION_ID: `${MOBILE_DEVICE}/session/create?project=`,
  TAKE_SCREENSHOT: `${MOBILE_DEVICE}/session/screenshot`,
  GET_PAGE_SOURCE: `${MOBILE_DEVICE}/session/source`,
  GET_MOBILE_APP_LIST: `${MOBILE_DEVICE}/appList?udid=`,
  DELETE_MOBILE_DRIVE: `${MOBILE_DEVICE}/session/delete`,
  INSTALL_MANDATORY_APP_IN_IOS: `${MOBILE_DEVICE}/setting`,
  GET_SMS_LIST: `${MOBILE_DEVICE}/session/sms`,
  SETTING_WIRELESS_DEVICE: `${MOBILE_DEVICE}/wireless`,

  // Project
  PROJECT_LIST: PROJECT,
  PROJECT_DETAIL: `${PROJECT}/`,
  GET_PROJECT_MEMBERS: `${PROJECT}/`,
  getProjectMembers: (projectIdx: string) => `${PROJECT}/${projectIdx}/list`,

  // Scheduler
  GET_ALL_SCHEDULER_LIST: `${SCHEDULER}?project=`,
  GET_SCHEDULER: `${SCHEDULER}/`,
  CREATE_SCHEDULER: `${LOCAL_SCHEDULER}`,
  UPDATE_SCHEDULER: `${LOCAL_SCHEDULER}`,
  UPDATE_SCHEDULER_STATUS: `${LOCAL_SCHEDULER}/`,
  DELETE_SCHEDULER: `${LOCAL_SCHEDULER}/delete`,
  SCHEDULER_INIT: `${LOCAL_SCHEDULER}/init`,

  //////////////////////////////////////////// UI Test ////////////////////////////////////////////////
  // Mobile Test Scenario
  GET_ALL_MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}?projectIdx=`,
  GET_EACH_MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}/`,
  CREATE_MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}?projectIdx=`,
  UPDATE_MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}/`,
  DELETE_MOBILE_TEST_SCENARIO: `${MOBILE_TEST_SCENARIO}`,

  // Mobile Test Case
  MOBILE_TEST_CASE: MOBILE_TEST_CASE,
  GET_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}?projectIdx=`,
  GET_DETAIL_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}/`,
  CREATE_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}?projectIdx=`,
  DELETE_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}`,
  UPDATE_TEST_CASE_LIST: `${MOBILE_TEST_CASE}/updateStep/`,
  UPDATE_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}/`,
  EXPORT_MOBILE_TEST_CASE: `${LOCAL_MOBILE_TEST_CASE}/export`,
  EXPORT_MOBILE_TEST_CASE_DATA: `${MOBILE_TEST_CASE}/export/data`,
  IMPORT_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}/import`,
  IMPORT_MOBILE_TEST_CASE_LIST: `${LOCAL_MOBILE_TEST_CASE}/import/load`,
  COPY_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}/copy/`,

  // Mobile Test Result
  getMobileTestResultList: (projectIdx: string, page: number, size: number) =>
    `${MOBILE_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`,
  getMobileTestResultDetail: (testResultIdx: string) =>
    `${MOBILE_TEST_RESULT}/${testResultIdx}/detail?ideRequest=true`,
  getMobileTestResultDetailInfo: (testResultIdx: string) =>
    `${MOBILE_TEST_RESULT}/${testResultIdx}`,
  getMobileTestCaseExecutionDetail: (
    testresultidx: string,
    caseResultIdx: number,
  ) => `${MOBILE_TEST_RESULT}/${testresultidx}/detail/${caseResultIdx}`,
  getMobileSpeedTestResult: (projectIdx: string, targetDate: string) =>
    `${MOBILE_SPEED_TEST_RESULT}?projectIdx=${projectIdx}&targetDate=${targetDate}`,

  // Mobile Test Step
  MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}`,
  GET_MOBILE_TEST_STEP_IN_TEST_CASE: `${MOBILE_TEST_STEP}?case=`,
  GET_EACH_MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}/`,
  CREATE_MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}?case=`,
  UPDATE_MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}/update/`,
  DELETE_MOBILE_TEST_STEP: `${MOBILE_TEST_STEP}?case=`,
  getMobileTestStepsByCommand: (apiName: string, idx: number) =>
    `${MOBILE_TEST_STEP}/command?command=${apiName}&case=${idx}`,

  // Mobile Execution
  RUN_TEST_SCENARIO: `${LOCAL_MOBILE_EXECUTION}/run?type=manual`,
  RUN_TEST_CASE: `${LOCAL_MOBILE_EXECUTION}/run?type=unit`,
  RUN_TEST_STEP: `${LOCAL_MOBILE_EXECUTION}/run?type=command`,
  DISTRIBUTION_TEST_EXEUCUTION: `${LOCAL_MOBILE_EXECUTION}/distribution`,
  STOP_MOBILE_TEST_EXECUTION: `${LOCAL_MOBILE_EXECUTION}/stop`,

  // Web Test Scenario
  GET_ALL_WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}?projectIdx=`,
  GET_EACH_WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}/`,
  CREATE_WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}?projectIdx=`,
  UPDATE_WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}/`,
  DELETE_WEB_TEST_SCENARIO: `${WEB_TEST_SCENARIO}`,
  OPEN_TEST_SCENARIO_WEBSOCKET_SERVER: `${WEB_TEST_SCENARIO}/websocket`,

  // Web Test Case
  WEB_TEST_CASE: `${BASE_URL}web/testcase`,
  WEB_TEST_CASE_UPDATE_STEP: `${WEB_TEST_CASE}/updateStep/`,
  GET_ALL_WEB_TEST_CASE: `${WEB_TEST_CASE}?projectIdx=`,
  CREATE_WEB_TEST_CASE: `${WEB_TEST_CASE}?projectIdx=`,
  DELETE_WEB_TEST_CASE: `${WEB_TEST_CASE}`,
  GET_WEB_TEST_CASE_DETAIL: `${WEB_TEST_CASE}/`,
  UPDATE_WEB_TEST_CASE_DETAIL: `${WEB_TEST_CASE}/`,
  WEB_TEST_CASE_OPEN_WEBSOCKET_SERVER: `${WEB_TEST_CASE}/websocket`,
  COPY_WEB_TEST_CASE: `${WEB_TEST_CASE}/copy/`,

  // Web Test Step
  WEB_TEST_STEP: `${WEB_TEST_STEP}`,
  GET_ALL_TEST_STEP: `${WEB_TEST_STEPS}?case=`,
  CREATE_WEB_TEST_STEP: `${WEB_TEST_STEP}`,
  DELETE_TEST_STEP: `${WEB_TEST_STEPS}`,
  getWebTestStepByCommand: (idx: number, serverName: string) =>
    `${WEB_TEST_STEPS}?case=${idx}&command=${serverName}`,
  GET_WEB_TEST_STEP: `${WEB_TEST_STEP}/`,
  DELETE_TEST_STEP_DETAIL: `${WEB_TEST_STEP}/`,
  UPDATE_WEB_TEST_STEP_DETAIL: `${WEB_TEST_STEP}/`,

  // Web Test Result
  GET_WEB_TEST_RESULT_DETAIL: `${WEB_TEST_RESULT}/`,
  getWebTestResultList: (projectIdx: string, page: number, size: number) =>
    `${WEB_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}&size=${size}`,
  getWebTestResultDetailInfo: (idx: string) =>
    `${WEB_TEST_RESULT}/${idx}/detail?ideRequest=true`,
  getWebTestCaseExecutionDetail: (idx: string, caseResultIdx: number) =>
    `${WEB_TEST_RESULT}/${idx}/detail/${caseResultIdx}`,

  // Web Test Execution
  RUN_WEB_TEST_SCENARIO: `${LOCAL_WEB_EXECUTION}/run?type=manual`,
  RUN_WEB_TEST_CASE: `${LOCAL_WEB_EXECUTION}/run?type=unit`,
  EXECUTE_WEB_SPEED_TEST: `${LOCAL_WEB_EXECUTION}/run?type=`,
  STOP_WEB_RUNNING_TEST: `${LOCAL_WEB_EXECUTION}/stop`,

  // Browser
  GET_BROWSER_LIST: `${LOCAL_URL}web/browser`,

  // Test Group
  GET_TEST_GROUP_LIST: `${BASE_URL}groups/case?project=`,
  CRUD_TEST_GROUP: `${BASE_URL}groups/case`,

  // Service Group
  GET_SERVICE_GROUP_LIST: `${BASE_URL}groups/service?project=`,
  CRUD_SERVICE_GROUP: `${BASE_URL}groups/service`,

  //////////////////////////////////////////// Speed Test ////////////////////////////////////////////////
  // Scheduler
  SPEED_TEST_SCHEDULER: `${SCHEDULER}/speed`,
  SPEED_TEST_LOCAL_SCHEDULER: `${LOCAL_SCHEDULER}/speed`,

  // Mobile Test Case
  SPEED_MOBILE_TEST_CASE: `${MOBILE_TEST_CASE}/speed`,

  // Mobile Web Test Step
  MOBILE_WEB_SPEED_TEST_STEP: `${MOBILE_WEB_SPEED_TEST_STEP}`,

  // Mobile Test Step
  GET_AND_CREATE_DELETE_MOBILE_SPEED_TEST_STEP: `${MOBILE_SPEED_TEST_STEP}?case=`,
  DETAIL_AND_UPDATE_MOBILE_SPEED_TEST_STEP: `${MOBILE_SPEED_TEST_STEP}/`,
  UPDATE_ORDER_MOBILE_SPEED_TEST_STEP: `${MOBILE_SPEED_TEST_STEP}/updateStep?case=`,
  REPEAT_MOBILE_SPEED_TEST_STEP: `${MOBILE_SPEED_TEST_STEP}/repeat`,
  getSpeedMobileTestStepsByCommand: (apiName: string, idx: number) =>
    `${MOBILE_SPEED_TEST_STEP}/command?command=${apiName}&case=${idx}`,
  RUN_SPEED_APP_TEST_STEP: `${LOCAL_MOBILE_EXECUTION}/run?type=`,

  // Web Test Case
  WEB_SPEED_TEST_CASE: `${WEB_SPEED_TEST_CASE}`,

  // Web Test Step
  WEB_SPEED_TEST_STEP: `${WEB_SPEED_TEST_STEP}`,

  // Mobile Test Result
  getIdeSpeedMobileTestResult: (
    projectIdx: string,
    page: number,
    size: number,
  ) =>
    `${MOBILE_SPEED_TEST_RESULT}/list?projectIdx=${projectIdx}&page=${page}&size=${size}`,
  DETAIL_SPEED_MOBILE_TEST_RESULT: `${MOBILE_SPEED_TEST_RESULT}`,
  DETAIL_SPEED_MOBILE_TEST_RESULT_BY_IDX: `${MOBILE_SPEED_TEST_RESULT}/detail/`,

  // Web Speed Test Result
  getWebSpeedTestResultList: (projectIdx: string, page: number) =>
    `${WEB_SPEED_TEST_RESULT}?projectIdx=${projectIdx}&page=${page}`,
  getWebSpeedTestResultDetail: (
    projectIdx: string,
    groupIdx: string,
    idx: string,
    start: string,
    end: string,
    orderBy: string,
    channel: string,
  ) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}?projectIdx=${projectIdx}&groupIdx=${groupIdx}&idx=${idx}&startDate=${start}&endDate=${end}&orderBy=${orderBy}&channel=${channel}`,
  getWebSpeedTestResultByIdx: (chartIdx: string) =>
    `${WEB_SPEED_TEST_RESULT_DETAIL}/repeat?resultIdx=${chartIdx}`,

  // Mobile Test Scenario
  MOBILE_SPEED_TEST_SCENARIO: MOBILE_SPEED_TEST_SCENARIO,

  // Web Test Scenario
  WEB_SPEED_TEST_SCENARIO: WEB_SPEED_TEST_SCENARIO,
};
