import { RootState } from '@app/store';
import { TooltipTop } from '@autosquare/common';
import PointOrRectangleInput from '@components/shared/PointOrRectangleInput';
import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { getCroppedImage } from '@utils/imageUtils';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const CompareImageCoordinate = () => {
  const {
    startX,
    startY,
    endX,
    endY,
    onChangeStartX,
    onChangeStartY,
    onChangeEndX,
    onChangeEndY,
    imageWidth,
    imageHeight,
    isComparingRectHidden,
    setIsComparingRectHidden,
  } = useMobileTestStepContext();

  const { setValue } = useFormContext<CreateTestStepData>();

  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const screenshot = useSelector(
    (state: RootState) => state.mobileScreenshotSource.screenshotSource,
  );

  useEffect(() => {
    setValue('optionsDto.conditionValue', undefined);
    return () => {
      setValue('optionsDto.conditionValue', undefined);
      setValue('optionsDto.conditionOptions', undefined);
      setIsComparingRectHidden(true);
    };
  }, [setValue]);

  useEffect(() => {
    setValue(
      'optionsDto.conditionValue',
      getCroppedImage(
        startX,
        startY,
        Math.abs(endX - startX),
        Math.abs(endY - startY),
        screenshot,
      ).split(',')[1],
    );
    setValue(
      'optionsDto.conditionOptions.cropCoordinate',
      `${startX},${startY},${endX},${endY}`,
    );
  }, [startX, startY, endX, endY, setValue]);

  const rectProperties = [
    {
      labelTitle: 'startX',
      maxSize: imageWidth,
      value: startX,
      onChange: onChangeStartX,
    },
    {
      labelTitle: 'startY',
      maxSize: imageHeight,
      value: startY,
      onChange: onChangeStartY,
    },
    {
      labelTitle: 'endX',
      maxSize: imageWidth,
      value: endX,
      onChange: onChangeEndX,
    },
    {
      labelTitle: 'endY',
      maxSize: imageHeight,
      value: endY,
      onChange: onChangeEndY,
    },
  ];

  return (
    <>
      <PointOrRectangleInput
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        properties={rectProperties}
        isHiddenInputArrow={true}
      />
      <div className="flex items-center gap-1">
        <p className="text-sm font-medium leading-tight text-gray-900">
          Compare Image Box
        </p>
        <div className="flex items-center gap-2.5">
          <div className="relative">
            <QuestionMarkCircleIcon
              className="size-5 cursor-help text-red-500"
              onMouseEnter={() => setIsMouseEnter(true)}
              onMouseLeave={() => setIsMouseEnter(false)}
            />
            {isMouseEnter && (
              <TooltipTop positionClass={'-left-36 bottom-6'}>
                스크린샷 화면에서 Compare Image Box를 숨길 수 있습니다.
              </TooltipTop>
            )}
          </div>
          <button
            type="button"
            onClick={() => setIsComparingRectHidden(!isComparingRectHidden)}
          >
            {isComparingRectHidden ? (
              <EyeIcon className="size-5" />
            ) : (
              <EyeSlashIcon className="size-5" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default CompareImageCoordinate;
