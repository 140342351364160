import React from 'react';

const GroupCreateFolderButton = () => {
  return (
    <div className="relative max-h-96 overflow-y-auto p-12 px-4 text-center">
      <svg
        className="mx-auto size-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <span className="mt-2 block text-sm font-semibold text-gray-900">
        생성된 그룹이 없습니다. 새로운 그룹을 생성해 주세요.
      </span>
    </div>
  );
};

export default GroupCreateFolderButton;
