import React from 'react';

interface Props {
  tableTitles: {
    idx: number;
    element: string | React.JSX.Element;
  }[];
}

const TestStepListTableHeader = ({ tableTitles }: Props) => {
  return (
    <thead>
      <tr>
        {tableTitles.map((tableTitle) => (
          <th scope="col" className={`table-header-basic`} key={tableTitle.idx}>
            {tableTitle.element}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TestStepListTableHeader;
