import {
  CreateSpeedMobileTestCaseData,
  SpeedMobileTestCaseData,
  UpdateSpeedMobileTestCaseData,
} from '@customTypes/speedTestCase/type';
import { axiosInstance } from '..';
import { IDEAPI } from '@api/ideEndpoints';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { speedMobileTestCaseKeys } from '@queries/ide/speedMobile/speedMobileTestCaseKeys';
import { ServerResponseErrorDetails } from '@customTypes/type';
import { useNavigate, useParams } from 'react-router-dom';
import { groupKeys, serviceGroupKeys } from '@queries/ide/groupKeys';

//GET
export const getSpeedMobileTestCaseList = async (
  projectIdx: string,
): Promise<SpeedMobileTestCaseData[]> => {
  const response = await axiosInstance.get<SpeedMobileTestCaseData[]>(
    IDEAPI.SPEED_MOBILE_TEST_CASE + '?project=' + projectIdx,
  );
  return response.data;
};

export const useSpeedMobileTestCaseListQuery = (
  options?: UseQueryOptions<SpeedMobileTestCaseData[], Error>,
) => {
  const { projectIdx } = useParams();

  return useQuery<SpeedMobileTestCaseData[], Error, SpeedMobileTestCaseData[]>({
    queryKey: speedMobileTestCaseKeys.list(projectIdx),
    queryFn: () => getSpeedMobileTestCaseList(projectIdx),
    ...options,
  });
};

export const getSpeedMobileTestCaseDetail = async (idx: string) => {
  const response = await axiosInstance.get<SpeedMobileTestCaseData>(
    `${IDEAPI.SPEED_MOBILE_TEST_CASE}/${idx}`,
  );
  return response.data;
};

export const useSpeedMobileTestCaseDetailQuery = (
  idx: string,
  options?: UseQueryOptions<
    SpeedMobileTestCaseData,
    Error,
    SpeedMobileTestCaseData
  >,
) =>
  useQuery<SpeedMobileTestCaseData, Error, SpeedMobileTestCaseData>({
    queryKey: speedMobileTestCaseKeys.detail(idx),
    queryFn: () => getSpeedMobileTestCaseDetail(idx),
    ...options,
  });

//POST
export const createSpeedMobileTestCase = async (
  data: CreateSpeedMobileTestCaseData,
): Promise<unknown> => {
  const response = await axiosInstance.post<unknown>(
    IDEAPI.SPEED_MOBILE_TEST_CASE,
    data,
  );
  return response.data;
};

export const useCreateSpeedMobileTestCaseMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    ServerResponseErrorDetails,
    CreateSpeedMobileTestCaseData
  >({
    mutationFn: (data) => createSpeedMobileTestCase(data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(serviceGroupKeys.lists());
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};
//PUT
export const updateSpeedMobileTestCase = async (
  idx: string,
  data: UpdateSpeedMobileTestCaseData,
): Promise<unknown> => {
  const response = await axiosInstance.put<unknown>(
    `${IDEAPI.SPEED_MOBILE_TEST_CASE}/${idx}`,
    data,
  );
  return response.data;
};

export const useUpdateSpeedMobileTestCaseMutation = (idx: string) => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, UpdateSpeedMobileTestCaseData>({
    mutationFn: (data) => updateSpeedMobileTestCase(idx, data),
    onSuccess: () => {
      queryClient.invalidateQueries(speedMobileTestCaseKeys.all);
      queryClient.invalidateQueries(serviceGroupKeys.lists());
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};

//DELETE
export const deleteSpeedMobileTestCaseMutation = async (data: {
  idxList: number[];
}): Promise<{ idxList: number[] }> => {
  const response = await axiosInstance.delete<{ idxList: number[] }>(
    IDEAPI.SPEED_MOBILE_TEST_CASE,
    { data: data },
  );
  return response.data;
};

export const useDeleteSpeedMobileTestCaseMutation = (projectIdx: string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation<{ idxList: number[] }, Error, { idxList: number[] }>({
    mutationFn: (data) => deleteSpeedMobileTestCaseMutation(data),
    onSuccess: () => {
      navigate(`/ide/projects/${projectIdx}/speed-test/mobile/test-case`);
      queryClient.invalidateQueries(speedMobileTestCaseKeys.lists());
      queryClient.invalidateQueries(serviceGroupKeys.lists());
      queryClient.invalidateQueries(groupKeys.lists());
    },
  });
};
