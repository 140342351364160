import React from 'react';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import IfCondition from './EditIfCommand/IfCondition';
import IfAction from './EditIfCommand/IfAction';

type Props = {
  mobileTestStepData: MobileTestStepData;
};

const EditIfCommand = ({ mobileTestStepData }: Props) => {
  return (
    <>
      <IfCondition mobileTestStepData={mobileTestStepData} />
      <IfAction mobileTestStepData={mobileTestStepData} />
    </>
  );
};

export default EditIfCommand;
