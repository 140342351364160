import { ClickId } from '@utils/static/clickOptions';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { z } from 'zod';

import { DeviceInfoOs } from '../device/device';
import { ExtractTextFormatTypeServerName } from './extractTextFormat/extractTextFormat';
import { SaveTextFrom } from './SaveTextFrom';
import {
  AddServerName,
  FromServerName,
  SeparatorServerName,
} from './textReplace/textReplace';

export enum CompareRange {
  Equal = 'equal',
  Contain = 'contain',
}

export enum CompareBy {
  Id = 'id',
  Text = 'text',
  Replace = 'replace',
}

export interface BoundsOptions {
  bounds: string | undefined;
}

export interface ActionOptions extends ElementOptions {
  repeatLimit: string | undefined;
  clickBy: ClickId;
}

export interface TextExtractOptions {
  type: ExtractTextFormatTypeServerName;
  length: number;
  index: number | string;
}

export interface ElementOptions {
  elementOptions: BoundsOptions;
}

export interface ConditionOptions extends ElementOptions {
  cropCoordinate: string;
}

export interface MobileTestStepByCase {
  aos: MobileTestStepData[];
  ios: MobileTestStepData[];
}

interface BaseTestStepData {
  mobileOs: DeviceInfoOs;
  command: MobileCommandOptionServerName;
  value: string;
  description: string;
  required: boolean;
  appVersion: string;
  packageName: string;
}

interface BaseTestStepDataOptionsDto extends ElementOptions {
  waitingTime: string;
  compareRange: CompareRange;
  compareBy: CompareBy;
  compareIdx: string;
  compareText: string;
  clickBy: ClickId;
  screenSize: string;
  cropCoordinate: string;
  conditionCommand: MobileCommandOptionServerName;
  conditionValue: string;
  conditionOptions: ConditionOptions;
  actionValue: string;
  repeatLimit: string;
  dpi: string;
  model: string;
  overrideDpi: string;
  overrideSize: string;
  viewportSize: string;
  passActionValue: string;
  passActionOptions: ElementOptions;
  failActionValue: string;
  failActionOptions: ElementOptions;
  loop: string;
  actionOptions: ActionOptions;
  from: SaveTextFrom;
  textExtract: 'true' | 'false';
  textExtractOptions: TextExtractOptions;
  textReplace: 'true' | 'false';
  textReplaceOptions: TextReplaceOptions;
  textEncrypt: 'true' | 'false';
  optionType: MobileCommandOptionServerName;
  stepIdxList: string;
}

export interface MobileTestStepData extends BaseTestStepData {
  idx: number;
  optionsDto: MobileTestStepDataOptionsDto;
}

export interface MobileTestStepDataOptionsDto
  extends BaseTestStepDataOptionsDto {
  udid: string;
}

export interface CreateTestStepData extends BaseTestStepData {
  optionsDto: CreateTestStepDataOptionsDto;
}

export interface CreateTestStepDataOptionsDto
  extends BaseTestStepDataOptionsDto {
  udid: string;
}

export interface Coordinate {
  x: string;
  y: string;
}

export interface UpdateTestStepData extends BaseTestStepData {
  optionsDto: UpdateTestStepDataOptionsDto;
  parsedValue?: Coordinate[];
  clickXValue?: string;
  clickYValue?: string;
  startX?: string;
  startY?: string;
  endX?: string;
  endY?: string;
}

export interface UpdateTestStepDataOptionsDto
  extends BaseTestStepDataOptionsDto {}

export interface TextReplaceOptions {
  from: FromServerName;
  addKey: AddServerName;
  addValue: number;
  dateFormat: string;
  separator: SeparatorServerName;
}

export type CreateTestStepDataKeys =
  | keyof CreateTestStepData
  | `optionsDto.${keyof CreateTestStepDataOptionsDto}`
  | `optionsDto.actionOptions.${keyof ActionOptions}`
  | `optionsDto.elementOptions.${keyof BoundsOptions}`;

export type UpdateTestStepDataKeys =
  | keyof UpdateTestStepData
  | `optionsDto.${keyof UpdateTestStepDataOptionsDto}`
  | `optionsDto.actionOptions.${keyof ActionOptions}`
  | `optionsDto.elementOptions.${keyof BoundsOptions}`;

export type RepeatInfo = {
  startIdx: number;
  endIdx: number;
};
export interface MobileSpeedTestStepList extends MobileTestStepByCase {
  aosRepeatInfo: RepeatInfo;
  iosRepeatInfo: RepeatInfo;
}

export const copyMobileTestStepSchema = z.object({
  targetCaseIdx: z.number({ required_error: '테스트 케이스를 선택해 주세요.' }),
  copyIdxList: z.array(z.number()),
});

export type CopyMobileTestStep = z.infer<typeof copyMobileTestStepSchema>;
