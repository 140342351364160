export type MobileCommandOptions = {
  idx: number;
  name: MobileCommandOptionName;
  serverName: MobileCommandOptionServerName;
  apiName: MobileCommandOptionApiName;
};

export enum MobileCommandOptionName {
  Command = 'Command',
  Click = 'Click',
  FindElement = 'Find Element',
  LaunchApp = 'Launch App',
  SaveText = 'Save Text',
  ConfirmText = 'Confirm Text',
  InputText = 'Input Text',
  ScrollSwipe = 'Scroll/Swipe',
  DeviceKey = 'Device Key',
  ResetApp = 'Reset App',
  ImportTestCase = 'Import Test Case',
  Loop = 'Loop',
  VerifyNoElement = 'Verify No Element',
  CompareImage = 'Compare Image',
  IF = 'IF',
  RemoveApp = 'Remove App',
  Wait = 'Wait',
  UnlockPattern = 'Unlock Pattern',
  ImportTestStep = 'Import Test Step',
}

export enum MobileCommandOptionServerName {
  Command = 'command',
  Click = 'click',
  FindElement = 'find_element',
  LaunchApp = 'launch_app',
  SaveText = 'save_text',
  ConfirmText = 'confirm_text',
  InputText = 'input_text',
  ScrollSwipe = 'scroll_and_swipe',
  DeviceKey = 'device_key',
  ResetApp = 'reset_app',
  ImportTestCase = 'import_test_case',
  Loop = 'loop',
  VerifyNoElement = 'verify_no_element',
  CompareImage = 'compare_image',
  IF = 'if',
  RemoveApp = 'remove_app',
  Wait = 'wait',
  UnlockPattern = 'unlock_pattern',
  ImportTestStep = 'import_test_step',
}

export enum MobileCommandOptionApiName {
  Command = 'command',
  Click = 'click',
  FindElement = 'findElement',
  LaunchApp = 'launchApp',
  SaveText = 'saveText',
  ConfirmText = 'confirmText',
  InputText = 'inputText',
  ScrollSwipe = 'scrollAndSwipe',
  DeviceKey = 'deviceKey',
  ResetApp = 'resetApp',
  ImportTestCase = 'importTestCase',
  Loop = 'loop',
  VerifyNoElement = 'verifyNoElement',
  CompareImage = 'compareImage',
  IF = 'if',
  RemoveApp = 'removeApp',
  Wait = 'wait',
  UnlockPattern = 'unlockPattern',
  ImportTestStep = 'importTestStep',
}

export const mobileCommandOptions: MobileCommandOptions[] = [
  {
    idx: 0,
    name: MobileCommandOptionName.Command,
    serverName: MobileCommandOptionServerName.Command,
    apiName: MobileCommandOptionApiName.Command,
  },
  {
    idx: 1,
    name: MobileCommandOptionName.Click,
    serverName: MobileCommandOptionServerName.Click,
    apiName: MobileCommandOptionApiName.Click,
  },
  {
    idx: 2,
    name: MobileCommandOptionName.FindElement,
    serverName: MobileCommandOptionServerName.FindElement,
    apiName: MobileCommandOptionApiName.FindElement,
  },
  {
    idx: 3,
    name: MobileCommandOptionName.LaunchApp,
    serverName: MobileCommandOptionServerName.LaunchApp,
    apiName: MobileCommandOptionApiName.LaunchApp,
  },
  {
    idx: 4,
    name: MobileCommandOptionName.SaveText,
    serverName: MobileCommandOptionServerName.SaveText,
    apiName: MobileCommandOptionApiName.SaveText,
  },
  {
    idx: 5,
    name: MobileCommandOptionName.ConfirmText,
    serverName: MobileCommandOptionServerName.ConfirmText,
    apiName: MobileCommandOptionApiName.ConfirmText,
  },
  {
    idx: 6,
    name: MobileCommandOptionName.InputText,
    serverName: MobileCommandOptionServerName.InputText,
    apiName: MobileCommandOptionApiName.InputText,
  },
  {
    idx: 7,
    name: MobileCommandOptionName.ScrollSwipe,
    serverName: MobileCommandOptionServerName.ScrollSwipe,
    apiName: MobileCommandOptionApiName.ScrollSwipe,
  },
  {
    idx: 8,
    name: MobileCommandOptionName.DeviceKey,
    serverName: MobileCommandOptionServerName.DeviceKey,
    apiName: MobileCommandOptionApiName.DeviceKey,
  },
  {
    idx: 9,
    name: MobileCommandOptionName.ResetApp,
    serverName: MobileCommandOptionServerName.ResetApp,
    apiName: MobileCommandOptionApiName.ResetApp,
  },
  {
    idx: 10,
    name: MobileCommandOptionName.ImportTestCase,
    serverName: MobileCommandOptionServerName.ImportTestCase,
    apiName: MobileCommandOptionApiName.ImportTestCase,
  },
  {
    idx: 11,
    name: MobileCommandOptionName.Loop,
    serverName: MobileCommandOptionServerName.Loop,
    apiName: MobileCommandOptionApiName.Loop,
  },
  {
    idx: 12,
    name: MobileCommandOptionName.VerifyNoElement,
    serverName: MobileCommandOptionServerName.VerifyNoElement,
    apiName: MobileCommandOptionApiName.VerifyNoElement,
  },
  {
    idx: 13,
    name: MobileCommandOptionName.CompareImage,
    serverName: MobileCommandOptionServerName.CompareImage,
    apiName: MobileCommandOptionApiName.CompareImage,
  },
  {
    idx: 14,
    name: MobileCommandOptionName.IF,
    serverName: MobileCommandOptionServerName.IF,
    apiName: MobileCommandOptionApiName.IF,
  },
  {
    idx: 15,
    name: MobileCommandOptionName.RemoveApp,
    serverName: MobileCommandOptionServerName.RemoveApp,
    apiName: MobileCommandOptionApiName.RemoveApp,
  },
  {
    idx: 16,
    name: MobileCommandOptionName.Wait,
    serverName: MobileCommandOptionServerName.Wait,
    apiName: MobileCommandOptionApiName.Wait,
  },
  {
    idx: 17,
    name: MobileCommandOptionName.UnlockPattern,
    serverName: MobileCommandOptionServerName.UnlockPattern,
    apiName: MobileCommandOptionApiName.UnlockPattern,
  },
  {
    idx: 18,
    name: MobileCommandOptionName.ImportTestStep,
    serverName: MobileCommandOptionServerName.ImportTestStep,
    apiName: MobileCommandOptionApiName.ImportTestStep,
  },
];

export const findMobileCommandNameFromServerName = (
  serverName: MobileCommandOptionServerName,
) =>
  mobileCommandOptions.find(
    (webCommand) => webCommand.serverName === serverName,
  )?.name;

export const findMobileServerNameFromName = (name: MobileCommandOptionName) =>
  mobileCommandOptions.find((webCommand) => webCommand.name === name)
    ?.serverName;

export const findMobileCommandOptionFromServerName = (
  serverName: MobileCommandOptionServerName,
) =>
  mobileCommandOptions.find(
    (webCommand) => webCommand.serverName === serverName,
  );
