import { SpeedMobileTestCaseData } from '@customTypes/speedTestCase/type';
import {
  TestCaseDetailData,
  WebTestCaseDetailData,
} from '@customTypes/testCase/type';

export const findTitleFromIdx = (
  idx: number,
  list: (
    | TestCaseDetailData
    | WebTestCaseDetailData
    | SpeedMobileTestCaseData
  )[],
) => list?.find((item) => item.idx === idx)?.title;
