import React from 'react';

import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '@utils/static/deviceKeyList';
import {
  MobileCommandOptionServerName,
  findMobileCommandNameFromServerName,
} from '@utils/static/mobileCommandOption';

import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import PropertyDetail from './PropertyDetail';
import Base64CroppedImage from '@components/TestStepCommon/Base64CroppedImage';
import { findTitleFromIdx } from '@utils/static/findTitleFromIdx';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';

type Props = {
  list: MobileTestStepData;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
};

const IfTestStepList = ({ list, setIsCopied }: Props) => {
  const { data: mobileTestCaseList } = useMobileTestCaseListQuery();

  const passCondition = list.value.split(',')[0];
  const failCondition = list.value.split(',')[1];

  return (
    <>
      <PropertyDetail
        term={'Condition'}
        description={findMobileCommandNameFromServerName(
          list.optionsDto.conditionCommand,
        )}
      />
      <PropertyDetail
        term={'Value'}
        description={
          list.optionsDto.conditionCommand ===
          MobileCommandOptionServerName.CompareImage ? (
            <Base64CroppedImage valueText={list.optionsDto.conditionValue} />
          ) : (
            list.optionsDto.conditionValue
          )
        }
        isRequiredCopy={
          list.optionsDto.conditionCommand !==
          MobileCommandOptionServerName.CompareImage
        }
        setIsCopied={setIsCopied}
      />
      <PropertyDetail
        term={'Pass'}
        description={findMobileCommandNameFromServerName(
          passCondition as MobileCommandOptionServerName,
        )}
      />
      <PropertyDetail
        term={'Value'}
        description={
          passCondition === MobileCommandOptionServerName.DeviceKey
            ? findListNameFromServerName(
                list.optionsDto.passActionValue as DeviceKeyServerName,
              )
            : passCondition === MobileCommandOptionServerName.ImportTestCase
              ? findTitleFromIdx(
                  Number(list.optionsDto.passActionValue),
                  mobileTestCaseList,
                )
              : list.optionsDto.passActionValue
        }
        isRequiredCopy={true}
        setIsCopied={setIsCopied}
      />
      {failCondition !== undefined &&
        list.optionsDto.failActionValue !== undefined && (
          <>
            <PropertyDetail
              term={'Fail'}
              description={findMobileCommandNameFromServerName(
                failCondition as MobileCommandOptionServerName,
              )}
            />
            <PropertyDetail
              term={'Value'}
              description={
                failCondition === MobileCommandOptionServerName.DeviceKey
                  ? findListNameFromServerName(
                      list.optionsDto.failActionValue as DeviceKeyServerName,
                    )
                  : failCondition ===
                      MobileCommandOptionServerName.ImportTestCase
                    ? findTitleFromIdx(
                        Number(list.optionsDto.failActionValue),
                        mobileTestCaseList,
                      )
                    : list.optionsDto.failActionValue
              }
              isRequiredCopy={true}
              setIsCopied={setIsCopied}
            />
          </>
        )}
    </>
  );
};

export default IfTestStepList;
