import { CommonDialog } from '@components/Dialog';
import {
  CopyMobileTestStep,
  copyMobileTestStepSchema,
} from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { TestCaseDetailData } from '@customTypes/testCase/type';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useCopyMobileTestStepMutation } from '@lib/api/ide/mobile/mobileTestStep';
import { findTitleFromIdx } from '@utils/static/findTitleFromIdx';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedArray: number[];
  uniqueImportTestIdxList: number[];
};

const MobileTestStepCopyDialog = ({
  isOpen,
  setIsOpen,
  selectedArray,
  uniqueImportTestIdxList,
}: Props) => {
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CopyMobileTestStep>({
    resolver: zodResolver(copyMobileTestStepSchema),
  });

  const targetCaseIdx = watch('targetCaseIdx');

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [caseIdx, setCaseIdx] = useState<number | undefined>(undefined);
  const [query, setQuery] = useState('');

  const copyMobileTestStepMutation = useCopyMobileTestStepMutation();

  const onSubmit: SubmitHandler<CopyMobileTestStep> = (data) => {
    copyMobileTestStepMutation.mutate(data, {
      onSuccess: () => {
        setCaseIdx(targetCaseIdx);
        setIsSuccess(true);
      },
      onError: () => {
        setIsError(true);
      },
    });
    setIsOpen(false);
  };

  const { data: mobileTestCaseList } = useMobileTestCaseListQuery({
    enabled: isOpen,
  });

  useEffect(() => {
    if (isOpen) {
      setValue('copyIdxList', selectedArray);
    }
  }, [isOpen, selectedArray]);

  useEffect(() => {
    if (!isOpen && mobileTestCaseList) {
      setTimeout(() => {
        setValue('copyIdxList', undefined);
        setValue('targetCaseIdx', undefined);
        clearErrors('targetCaseIdx');
      }, 400);
    }
  }, [isOpen, mobileTestCaseList]);

  const goToCopiedStepOnClick = () => {
    navigate({ search: `idx=${caseIdx}` });
    setCaseIdx(undefined);
    setIsSuccess(false);
  };

  const filteredTestCaseList =
    query === ''
      ? mobileTestCaseList
      : mobileTestCaseList.filter((mobileTestCase) =>
          mobileTestCase.title.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <>
      <CommonDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        iconType={'copy'}
        title={'Copy Test Step'}
        subTitle={'해당 테스트 스텝을 복사할 테스트 케이스를 선택해 주세요.'}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CommonDialog.DialogPanels>
            <Controller
              control={control}
              name="targetCaseIdx"
              render={({ field }) => (
                <CommonDialog.DialogCombobox
                  value={field.value || ''}
                  onChange={field.onChange}
                  setQuery={setQuery}
                  displayValue={(idx: number) =>
                    findTitleFromIdx(idx, mobileTestCaseList) || query
                  }
                  filteredList={filteredTestCaseList}
                  valueToSave={'idx'}
                  valueToShow={'title'}
                  placeholder={'Select Test Case'}
                  isOptionDisabled={(item: TestCaseDetailData) =>
                    uniqueImportTestIdxList.includes(item.idx)
                  }
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="targetCaseIdx"
              render={({ message }) => (
                <p className="standard-error-message">{message}</p>
              )}
            />
          </CommonDialog.DialogPanels>
          <CommonDialog.DialogButtonPanels>
            <CommonDialog.DialogButton
              variant="primary"
              type="submit"
              disabled={copyMobileTestStepMutation.isLoading}
            >
              Copy
            </CommonDialog.DialogButton>
            <CommonDialog.DialogButton
              variant="secondary"
              type="button"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </CommonDialog.DialogButton>
          </CommonDialog.DialogButtonPanels>
        </form>
      </CommonDialog>
      <CommonDialog
        isOpen={isSuccess}
        setIsOpen={setIsSuccess}
        iconType={'copy'}
        title={'Test step copy complete'}
        subTitle={'테스트 스텝 복사가 완료되었습니다.'}
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            variant="primary"
            type="button"
            onClick={goToCopiedStepOnClick}
          >
            Go to copied step
          </CommonDialog.DialogButton>
          <CommonDialog.DialogButton
            variant="secondary"
            type="button"
            onClick={() => setIsSuccess(false)}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
      <CommonDialog
        isOpen={isError}
        setIsOpen={setIsError}
        iconType={'caution'}
        title={'Error'}
        subTitle={
          copyMobileTestStepMutation.isError &&
          copyMobileTestStepMutation.error.message
        }
      >
        <CommonDialog.DialogButtonPanels>
          <CommonDialog.DialogButton
            variant="primary"
            type="button"
            onClick={() => setIsError(false)}
          >
            Close
          </CommonDialog.DialogButton>
        </CommonDialog.DialogButtonPanels>
      </CommonDialog>
    </>
  );
};

export default MobileTestStepCopyDialog;
