import { useImageSize } from '@hooks/testStep/useImageSize';
import React from 'react';
import { Layer, Stage, Image } from 'react-konva';
import useImage from 'use-image';

type Props = {
  valueText: string;
};

const Base64CroppedImage = ({ valueText }: Props) => {
  const [image] = useImage(`data:image/png;base64,${valueText}`);
  const { imageWidth, imageHeight, divRef } = useImageSize(image, valueText);

  return (
    <div
      className="flex items-center justify-center text-xs leading-6 text-gray-700"
      ref={divRef}
    >
      <Stage width={imageWidth} height={imageHeight}>
        <Layer>
          <Image image={image} width={imageWidth} height={imageHeight} />
        </Layer>
      </Stage>
    </div>
  );
};

export default Base64CroppedImage;
