import React from 'react';

import TestStepConfirmOrInputText from './TestStepConfirmOrInputText/TestStepConfirmOrInputText';
import TestStepScrollAndSwipe from './TestStepScrollAndSwipe/TestStepScrollAndSwipe';
import TestStepSelectBox from './TestStepSelectBox/TestStepSelectBox';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';

import { WebTestStepData } from '@customTypes/testStep/type';
import WebTestStepTextareaByReactHookForm from '@components/shared/IDE/WebTestStepDetail/WebTestStepTextareaByReactHookForm';

interface Props {
  webTestStep: WebTestStepData;
}

const TestStepValue = ({ webTestStep }: Props) => {
  return (
    <>
      {webTestStep.command === WebCommandOptionServerName.ImportTestCase ||
      webTestStep.command === WebCommandOptionServerName.BrowserKey ||
      webTestStep.command === WebCommandOptionServerName.KeyBoardEvent ||
      WebCommandOptionServerName.ImportTestStep ? (
        <TestStepSelectBox command={webTestStep.command} />
      ) : webTestStep.command === WebCommandOptionServerName.ScrollAndSwipe ? (
        <TestStepScrollAndSwipe webTestStep={webTestStep} />
      ) : webTestStep.command === WebCommandOptionServerName.ConfirmText ||
        webTestStep.command === WebCommandOptionServerName.InputText ? (
        <TestStepConfirmOrInputText webTestStep={webTestStep} />
      ) : (
        <WebTestStepTextareaByReactHookForm term={'Value*'} name={'value'} />
      )}
    </>
  );
};

export default TestStepValue;
