import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { WebTestStepData } from '@customTypes/testStep/type';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';
import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import { useEffect, useState } from 'react';

export const useTestStepCopyValidation = (
  testStepLists: (MobileTestStepData | WebTestStepData)[],
  selectedArray: number[],
) => {
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [copyErrorMessage, setCopyErrorMessage] = useState('');

  // selectedArray에 save text 가 참조되어있는지 유효성 검증 로직
  const selectedTestSteps = testStepLists?.filter((testStep) =>
    selectedArray.includes(testStep.idx),
  );

  const stepsWithCompareIdx = selectedTestSteps?.filter(
    (item) => item.optionsDto.compareIdx,
  );

  const compareIdxArray = stepsWithCompareIdx?.map((item) =>
    Number(item.optionsDto.compareIdx),
  );

  const compareIdxArraySet = new Set(compareIdxArray);

  const uniqueCompareIdxArray = [...compareIdxArraySet];

  const copyAllButtonOnClick = () => {
    if (uniqueCompareIdxArray.length === 0) {
      setIsCopyDialogOpen(true);
    }

    if (uniqueCompareIdxArray.length > 0) {
      if (uniqueCompareIdxArray.every((item) => selectedArray.includes(item))) {
        setIsCopyDialogOpen(true);
      } else {
        setCopyErrorMessage('참조되는 Save Text 스텝을 같이 선택해 주세요.');
      }
    }
  };

  // import test case 또는 import test step이 있는지 확인하여 index 추출
  const importCommandTestStepList = selectedTestSteps?.filter(
    (testStep) =>
      testStep.command === MobileCommandOptionServerName.ImportTestCase ||
      testStep.command === MobileCommandOptionServerName.ImportTestStep ||
      testStep.command === WebCommandOptionServerName.ImportTestCase ||
      testStep.command === WebCommandOptionServerName.ImportTestStep,
  );

  const importCommandTestValueList = importCommandTestStepList?.map(
    (testStep) => Number(testStep.value),
  );

  const importCommandTestValueSet = new Set(importCommandTestValueList);

  const uniqueImportTestIdxList = [...importCommandTestValueSet];

  // selectedArray 가 변경될 때 마다 에러 메시지 초기화
  useEffect(() => {
    if (copyErrorMessage) {
      setCopyErrorMessage('');
    }
  }, [selectedArray]);

  return {
    isCopyDialogOpen,
    setIsCopyDialogOpen,
    copyErrorMessage,
    copyAllButtonOnClick,
    uniqueImportTestIdxList,
  };
};
