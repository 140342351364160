import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { useMobileTestCaseListQuery } from '@lib/api/ide/mobile/mobileTestCase';
import { useSpeedMobileTestCaseListQuery } from '@lib/api/ide/speedMobile/speedMobileTestCase';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';
import React from 'react';
import { ClickId } from '../clickOptions';
import {
  DeviceKeyServerName,
  findListNameFromServerName,
} from '../deviceKeyList';
import { findTitleFromIdx } from '../findTitleFromIdx';
import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionServerName,
} from '../mobileCommandOption';

export const getTestStepDetailListValue = (
  mobileTestStep: MobileTestStepData,
  mobileTestStepList: MobileTestStepData[],
) => {
  const location = isWordInLocationPathname('ui-test')
    ? 'ui-test'
    : 'speed-test';

  const mobileTestCaseListQuery = useMobileTestCaseListQuery({
    enabled: location === 'ui-test',
  });

  const speedMobileCaseListQuery = useSpeedMobileTestCaseListQuery({
    enabled: location === 'speed-test',
  });

  const mobileTestCaseList =
    location === 'ui-test'
      ? mobileTestCaseListQuery.data
      : speedMobileCaseListQuery.data;

  const testCaseTitle = findTitleFromIdx(
    Number(mobileTestStep.value),
    mobileTestCaseList,
  );
  const importTestStepCount =
    mobileTestStep.optionsDto?.stepIdxList?.split(',').length;

  switch (mobileTestStep.command) {
    case MobileCommandOptionServerName.Click:
      if (mobileTestStep.optionsDto.clickBy === ClickId.Coordinate) {
        return `[${mobileTestStep.value}]`;
      } else {
        return mobileTestStep.value;
      }

    case MobileCommandOptionServerName.ScrollSwipe:
      return `[${mobileTestStep.value}]`;

    case MobileCommandOptionServerName.ConfirmText:
    case MobileCommandOptionServerName.InputText:
      if (mobileTestStep.optionsDto.compareBy === 'text') {
        if (mobileTestStep.optionsDto.textEncrypt === 'true') {
          return `${mobileTestStep.value}\ntarget text="**********"`;
        } else {
          return `${mobileTestStep.value}\ntarget text="${mobileTestStep.optionsDto.compareText}"`;
        }
      }
      if (mobileTestStep.optionsDto.compareBy === 'id') {
        const compareIdx =
          mobileTestStepList
            ?.map((item) => item?.idx)
            .indexOf(parseInt(mobileTestStep.optionsDto.compareIdx)) + 1;
        return `${mobileTestStep.value}\ntarget text=#${compareIdx}`;
      }
      break;

    case MobileCommandOptionServerName.DeviceKey:
      return findListNameFromServerName(
        mobileTestStep.value as DeviceKeyServerName,
      );

    case MobileCommandOptionServerName.Loop:
      return (
        <>
          <p>
            Condition=&quot;
            {findMobileCommandNameFromServerName(
              mobileTestStep.optionsDto.conditionCommand,
            )}
            &quot;
          </p>
          <p>
            Action=&quot;
            {findMobileCommandNameFromServerName(
              mobileTestStep.value as MobileCommandOptionServerName,
            )}
            &quot;
          </p>
        </>
      );

    case MobileCommandOptionServerName.IF:
      return (
        <>
          <div>
            <p>Condition</p>
            <p>
              {findMobileCommandNameFromServerName(
                mobileTestStep.optionsDto.conditionCommand,
              )}
              , {mobileTestStep.optionsDto.conditionValue}
            </p>
          </div>
          <br />
          <div>
            <p>PASS</p>
            {findMobileCommandNameFromServerName(
              mobileTestStep.value.split(
                ',',
              )[0] as MobileCommandOptionServerName,
            )}
            , {mobileTestStep.optionsDto.passActionValue}
          </div>
          <br />
          <div>
            <p>FAIL</p>
            {mobileTestStep.value.split(',')[1] !== undefined &&
            mobileTestStep.optionsDto.failActionValue !== undefined
              ? `${findMobileCommandNameFromServerName(
                  mobileTestStep.value.split(
                    ',',
                  )[1] as MobileCommandOptionServerName,
                )}, ${mobileTestStep.optionsDto.failActionValue}`
              : 'None'}
          </div>
        </>
      );

    case MobileCommandOptionServerName.ImportTestCase:
      return testCaseTitle;

    case MobileCommandOptionServerName.ImportTestStep:
      return `${testCaseTitle} / ${importTestStepCount}`;
    default:
      return mobileTestStep.value;
  }
};
