import React, { Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useFormContext,
  Controller,
  ControllerRenderProps,
} from 'react-hook-form';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

import { WebCommandOptionServerName } from '@utils/static/webCommandOptions';
import { useWebTestCaseListQuery } from '@lib/api/ide/web/webTestCase';
import {
  browserKeyList,
  BrowserKeyName,
  findBrowserKeyNameFromServerName,
} from '@utils/static/browserKeyList';
import {
  findKeyboardEventNameFromServerName,
  keyboardEventList,
  KeyboardEventServerName,
} from '@utils/static/keyboardEventList';

import { WebTestStepData } from '@customTypes/testStep/type';
import TestStepCheckBoxTable from '../TestStepCheckBoxTable/TestStepCheckBoxTable';
import { ErrorMessage } from '@hookform/error-message';
import { useCheckbox } from '@autosquare/common';
import { useWebTestStepListQuery } from '@lib/api/ide/web/webTestStep';

interface Props {
  command: string;
}

const TestStepSelectBox = ({ command }: Props) => {
  const [searchParams] = useSearchParams();
  const idx = Number(searchParams.get('idx'));

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<WebTestStepData>();

  const webTestCaseListQuery = useWebTestCaseListQuery({
    enabled:
      command ===
      (WebCommandOptionServerName.ImportTestCase ||
        WebCommandOptionServerName.ImportTestStep),
  });

  const webTestCaseListData = webTestCaseListQuery.data;

  const webTestCaseList = webTestCaseListData?.filter(
    (webTestCase) => webTestCase?.idx !== idx,
  );
  const selectedIdxList = watch('optionsDto.stepIdxList')
    ?.split(',')
    ?.map((item) => Number(item));

  const testCaseIdx = watch('value');

  const webTestStepDetailQuery = useWebTestStepListQuery(testCaseIdx);
  const webTestStepsDetail = webTestStepDetailQuery.data;
  const webTestStepDetailWithoutDisabled = webTestStepsDetail?.filter(
    (item) =>
      !(
        item.command.includes('import') ||
        (item.command === WebCommandOptionServerName.IF &&
          item.value.includes('import'))
      ),
  );

  const { selectAllArray, selectArray, selectedArray, resetSelectedArray } =
    useCheckbox(webTestStepDetailWithoutDisabled, 'idx', selectedIdxList);

  const onChangeHandler = (
    field: ControllerRenderProps<WebTestStepData, 'value'>,
    value: string,
  ) => {
    if (command === WebCommandOptionServerName.ImportTestStep) {
      resetSelectedArray();
    }
    field.onChange(value);
  };
  return (
    <div className="items-start border-b border-gray-200 sm:flex sm:px-6 sm:py-5">
      <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:shrink-0 lg:w-48">
        Value*
      </dt>
      <div className="flex w-4/6 flex-col">
        <dd className="mt-1 w-full text-sm text-gray-900 sm:col-span-2 sm:ml-6 sm:mt-0">
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <Listbox
                as="div"
                value={field.value}
                onChange={(value) => onChangeHandler(field, value)}
              >
                {({ open }) => (
                  <>
                    <div className="relative">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pb-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {command === WebCommandOptionServerName.BrowserKey
                            ? findBrowserKeyNameFromServerName(
                                field.value as BrowserKeyName,
                              )
                            : command ===
                                WebCommandOptionServerName.KeyBoardEvent
                              ? findKeyboardEventNameFromServerName(
                                  field.value as KeyboardEventServerName,
                                )
                              : webTestCaseList?.find(
                                  (webTestCase) =>
                                    webTestCase?.idx === Number(field.value),
                                )?.title}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="size-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>
                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions className="absolute z-10 mt-1 max-h-96 w-full overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                          {command === WebCommandOptionServerName.BrowserKey
                            ? browserKeyList.map((browserKey) => (
                                <ListboxOption
                                  key={browserKey.id}
                                  className={clsx(
                                    'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                                  )}
                                  value={browserKey.serverName}
                                >
                                  <span
                                    className={clsx(
                                      'block break-all font-normal group-data-[selected]:font-medium',
                                    )}
                                  >
                                    {browserKey.name}
                                  </span>
                                  <span
                                    className={clsx(
                                      'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                    )}
                                  >
                                    <CheckIcon
                                      className="invisible size-5 group-data-[selected]:visible"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </ListboxOption>
                              ))
                            : command ===
                                WebCommandOptionServerName.KeyBoardEvent
                              ? keyboardEventList.map((keyboardKey) => (
                                  <ListboxOption
                                    key={keyboardKey.id}
                                    value={keyboardKey.serverName}
                                    className={clsx(
                                      'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                                    )}
                                  >
                                    <>
                                      <span
                                        className={clsx(
                                          'block break-all font-normal group-data-[selected]:font-medium',
                                        )}
                                      >
                                        {keyboardKey.name}
                                      </span>
                                      <span
                                        className={clsx(
                                          'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                        )}
                                      >
                                        <CheckIcon
                                          className="invisible size-5 group-data-[selected]:visible"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </>
                                  </ListboxOption>
                                ))
                              : webTestCaseList?.map((webTestCase) => (
                                  <ListboxOption
                                    key={webTestCase?.idx}
                                    className={clsx(
                                      'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[disabled]:text-gray-300 data-[focus]:text-white',
                                    )}
                                    value={webTestCase?.idx.toString()}
                                    disabled={
                                      command ===
                                        WebCommandOptionServerName.ImportTestStep &&
                                      (webTestCase.stepCount === 0 ||
                                        idx === webTestCase.idx)
                                    }
                                  >
                                    <>
                                      <span
                                        className={clsx(
                                          'block break-all font-normal group-data-[selected]:font-medium',
                                        )}
                                      >
                                        {webTestCase?.title}
                                      </span>
                                      <span
                                        className={clsx(
                                          'absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white',
                                        )}
                                      >
                                        <CheckIcon
                                          className="invisible size-5 group-data-[selected]:visible"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </>
                                  </ListboxOption>
                                ))}
                        </ListboxOptions>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            )}
          />
        </dd>
        {command === WebCommandOptionServerName.ImportTestStep && (
          <>
            <TestStepCheckBoxTable
              selectAllArray={selectAllArray}
              selectArray={selectArray}
              selectedArray={selectedArray}
              query={webTestStepDetailQuery}
            />
            <ErrorMessage
              name="optionsDto.stepIdxList"
              errors={errors}
              render={({ message }) => (
                <p className="standard-error-message ml-6">{message}</p>
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TestStepSelectBox;
