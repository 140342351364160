import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import { ListBoxHeadlessInController } from '@autosquare/common';

import { RootState } from '@app/store';

import {
  findMobileCommandNameFromServerName,
  MobileCommandOptionName,
  mobileCommandOptions,
  MobileCommandOptionServerName,
} from '@utils/static/mobileCommandOption';

import { DeviceInfoOs } from '@customTypes/ide/device/device';
import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import {
  useMobileScreenshotQuery,
  useMobileSourceQuery,
} from '@lib/api/ide/mobile/mobileDevice';
import { isWordInLocationPathname } from '@utils/isWordInLocationPathname';

const TestStepCommand = () => {
  const mobileScreenshotQuery = useMobileScreenshotQuery();
  const mobileSourceQuery = useMobileSourceQuery();

  const deviceInfo = useSelector((state: RootState) => state.device.deviceInfo);

  const iOSMobileCommandOptions = mobileCommandOptions.filter(
    (mobileCommandOption) =>
      mobileCommandOption.name !== MobileCommandOptionName.ResetApp,
  );

  const { control, watch, setValue, resetField } =
    useFormContext<CreateTestStepData>();

  const command = watch('command');

  useEffect(() => {
    setValue('optionsDto.optionType', command);
  }, [setValue, command]);

  const isUiLocation = isWordInLocationPathname('ui-test');

  //Todo: Speed Test > Import Test Step 명령어 백엔드쪽 개발 완료되면 해당 변수 삭제 및 원상 복구!
  const mobileCommandOptionsWithoutImportTestStep = isUiLocation
    ? mobileCommandOptions
    : mobileCommandOptions.filter(
        (mobileCommand) =>
          mobileCommand.serverName !==
          MobileCommandOptionServerName.ImportTestStep,
      );
  //Todo: Speed Test > Import Test Step 명령어 백엔드쪽 개발 완료되면 해당 변수 삭제 및 원상 복구!
  const iOSMobileCommandOptionsWithoutImportTestStep = isUiLocation
    ? iOSMobileCommandOptions
    : iOSMobileCommandOptions.filter(
        (mobileCommand) =>
          mobileCommand.serverName !==
          MobileCommandOptionServerName.ImportTestStep,
      );

  return (
    <Controller
      control={control}
      name="command"
      rules={{ required: 'Command를 입력해주세요.' }}
      render={({ field }) => (
        <ListBoxHeadlessInController
          value={field.value}
          buttonValue={
            findMobileCommandNameFromServerName(field.value) ||
            MobileCommandOptionName.Command
          }
          onChange={(value) => {
            field.onChange(value);
            resetField('value');
            if (
              command === MobileCommandOptionServerName.Loop ||
              command === MobileCommandOptionServerName.IF
            ) {
              resetField('optionsDto.conditionCommand');
              resetField('optionsDto.conditionValue');
            }
          }}
          lists={
            deviceInfo.os === DeviceInfoOs.Aos
              ? mobileCommandOptionsWithoutImportTestStep.slice(1)
              : iOSMobileCommandOptionsWithoutImportTestStep.slice(1)
          }
          valueToSave={'serverName'}
          valueToShow={'name'}
          disabled={
            mobileScreenshotQuery.isLoading || mobileSourceQuery.isLoading
          }
        />
      )}
    />
  );
};

export default TestStepCommand;
