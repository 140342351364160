/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImportTestStepFilteredList } from '@autosquare/common/src/utils/type';
import {
  TestCaseDetailData,
  WebTestCaseDetailData,
} from '@customTypes/testCase/type';

export const removeMobileUnusedFields = (
  {
    priority,
    dndIndex,
    updatedAt,
    caseGroupInfo,
    creator,
    steps,
    ...rest
  }: TestCaseDetailData,
  selectedOsCount: string,
) => ({
  ...rest,
  disabled: steps?.[selectedOsCount] === 0,
});

export const removeWebUnusedFields = ({
  priority,
  stepCount,
  updatedAt,
  creator,
  caseGroupInfo,
  ...rest
}: WebTestCaseDetailData) => ({
  ...rest,
  disabled: stepCount === 0,
});

export const filterSelectedIdx = (
  caseData: ImportTestStepFilteredList,
  idx: string,
) => {
  return caseData.idx !== Number(idx);
};
