import { ErrorMessage, PhotoSkeleton } from '@autosquare/common';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { RootState } from '@app/store';

import { useMobileTestStepContext } from '@contexts/ide/MobileTestStepProvider';

import {
  useMobileScreenshotQuery,
  useMobileSourceQuery,
} from '@lib/api/ide/mobile/mobileDevice';

import useSetupCoordinates from '@hooks/testStep/useSetupCoordinates';

import { mobileDeviceKeys } from '@queries/ide/mobile/mobileDeviceKeys';

import { getMobileScreenshotSource } from '@store/ide/mobileTestStep/mobileScreenshotSourceSlice';

import { CreateTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';

import AddTestStep from './MainTestStep/AddTestStep';
import ScreenShot from './MainTestStep/ScreenShot';
import TestStepList from './MainTestStep/TestStepList/TestStepList';
import ToolButton from './MainTestStep/ToolButton';
import CountDownAnimation from './MainTestStep/CountDownAnimation';
import TestStepListDialog from './MainTestStep/TestStepListDialog';
import LandScapeTestStepListButton from './MainTestStep/LandScapeTestStepListButton';
import { useLocation } from 'react-router-dom';
import SpeedTestStepList from './MainTestStep/SpeedTestStepList';
import clsx from 'clsx';

const MainTestStep = () => {
  const {
    isCountThreeNeeded,
    setIsCountThreeNeeded,
    countDown,
    setCountDown,
    setIsListboxOpened,
    isLandscapeMode,
  } = useMobileTestStepContext();

  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [isTestStepListOpened, setIsTestStepListOpened] = useState(false);

  const connectedDeviceInfo = useSelector(
    (state: RootState) => state.device.deviceInfo,
  );
  const methods = useForm<CreateTestStepData>({
    defaultValues: {
      mobileOs: connectedDeviceInfo.os,
    },
  });

  const { pathname } = useLocation();
  const location = pathname.includes('ui-test') ? 'ui-test' : 'speed-test';

  const command = methods.watch('command');
  const value = methods.watch('value');
  const clickBy = methods.watch('optionsDto.clickBy');
  const conditionCommand = methods.watch('optionsDto.conditionCommand');

  const mobileScreenshotQuery = useMobileScreenshotQuery();
  const mobileSourceQuery = useMobileSourceQuery();
  const mobileSource = mobileSourceQuery.data;

  //screenshot 저장
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMobileScreenshotSource(mobileScreenshotQuery.data));
  }, [mobileScreenshotQuery.data]);

  // coordinate
  const getCoordinate = mobileSource?.page_source[0];
  const coordinateInfo = mobileSource?.page_source[1];

  const appInfo = mobileSource?.app_info;

  useSetupCoordinates(command, clickBy, value, conditionCommand);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isCountThreeNeeded) {
      const timer = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);

      if (countDown === 0) {
        queryClient.refetchQueries({ queryKey: mobileDeviceKeys.screenshot() });
        queryClient.refetchQueries({ queryKey: mobileDeviceKeys.source() });
        setIsCountThreeNeeded(false);
        setIsListboxOpened(true);
        setCountDown(3);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown, isCountThreeNeeded]);

  return (
    <div className="flex">
      <ToolButton
        refetch={() => {
          mobileScreenshotQuery.refetch();
          mobileSourceQuery.refetch();
          setIsListboxOpened(true);
        }}
        appInfo={appInfo}
      />
      <div
        className={clsx(
          'mb-2 grid h-[calc(100lvh-6rem)] w-full grid-cols-10 gap-4',
          isLandscapeMode ? 'ml-4 mr-11' : 'mx-4',
        )}
      >
        <FormProvider {...methods}>
          {isCountThreeNeeded ? (
            <CountDownAnimation countDown={countDown} />
          ) : mobileScreenshotQuery.isLoading ||
            mobileSourceQuery.isLoading ||
            mobileScreenshotQuery.isFetching ||
            mobileSourceQuery.isFetching ? (
            <div
              className={clsx(
                'flex',
                isLandscapeMode ? 'col-span-7' : 'col-span-4',
              )}
            >
              <PhotoSkeleton />
            </div>
          ) : mobileScreenshotQuery.isError ? (
            <div
              className={clsx(
                'flex w-full items-center justify-center border',
                isLandscapeMode ? 'col-span-7' : 'col-span-4',
              )}
            >
              <ErrorMessage margin={'m-auto'}>
                {mobileScreenshotQuery.error.message}
              </ErrorMessage>
            </div>
          ) : mobileSourceQuery.isError ? (
            <div
              className={clsx(
                'flex w-full items-center justify-center border',
                isLandscapeMode ? 'col-span-7' : 'col-span-4',
              )}
            >
              <ErrorMessage margin={'m-auto'}>
                {mobileSourceQuery.error.message}
              </ErrorMessage>
            </div>
          ) : (
            <ScreenShot
              screenshot={mobileScreenshotQuery.data}
              getCoordinate={getCoordinate}
              coordinateInfo={coordinateInfo}
            />
          )}
          <AddTestStep
            deviceInfo={mobileSourceQuery.data?.device_info}
            appInfo={appInfo}
          />
        </FormProvider>
        {isLandscapeMode && !isTestStepListOpened && (
          <LandScapeTestStepListButton
            isTestStepListOpened={isTestStepListOpened}
            setIsTestStepListOpened={setIsTestStepListOpened}
            isTooltipOpened={isTooltipOpened}
            setIsTooltipOpened={setIsTooltipOpened}
          />
        )}
        {isLandscapeMode ? (
          <TestStepListDialog
            isOpen={isTestStepListOpened}
            setIsOpen={setIsTestStepListOpened}
            location={location}
          />
        ) : pathname.includes('ui-test') ? (
          <TestStepList />
        ) : (
          pathname.includes('speed-test') && <SpeedTestStepList />
        )}
      </div>
    </div>
  );
};

export default MainTestStep;
