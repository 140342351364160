import { MobileTestStepData } from '@customTypes/ide/mobileTestStep/mobileTestStep';
import { MobileCommandOptionServerName } from '@utils/static/mobileCommandOption';

export const stepDisabledExecution = (
  data: MobileTestStepData,
  passCommand: string,
  failCommand: string,
) => {
  const disabledExecution =
    (data.command === MobileCommandOptionServerName.ConfirmText &&
      data.optionsDto.compareBy === 'id') ||
    (data.command === MobileCommandOptionServerName.InputText &&
      data.optionsDto.compareBy === 'id') ||
    data.command === MobileCommandOptionServerName.ImportTestCase ||
    data.command === MobileCommandOptionServerName.ImportTestStep ||
    (data.command === MobileCommandOptionServerName.IF &&
      (passCommand === MobileCommandOptionServerName.ImportTestCase ||
        failCommand === MobileCommandOptionServerName.ImportTestCase));

  return { disabledExecution };
};
