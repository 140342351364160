import React, { createContext, ReactNode, useContext, useState } from 'react';

interface MobileTestCaseDetailProviderContext {
  isOpenedCreateGroup: boolean;
  setIsOpenedCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileTestCaseDetailContext =
  createContext<MobileTestCaseDetailProviderContext>({
    isOpenedCreateGroup: false,
    setIsOpenedCreateGroup: () => {},
  });

export const useMobileTestCaseDetailContext = () => {
  return useContext(MobileTestCaseDetailContext);
};

interface Props {
  children: ReactNode;
}

const MobileTestCaseDetailProvider = ({ children }: Props) => {
  const [isOpenedCreateGroup, setIsOpenedCreateGroup] = useState(false);

  return (
    <MobileTestCaseDetailContext.Provider
      value={{ isOpenedCreateGroup, setIsOpenedCreateGroup }}
    >
      {children}
    </MobileTestCaseDetailContext.Provider>
  );
};

export default MobileTestCaseDetailProvider;
