import { useGroupContext } from '@contexts/ide/GroupProvider';
import { MenuItem, MenuItems, Transition } from '@headlessui/react';
import clsx from 'clsx';

import React, { Fragment } from 'react';

type Props = {
  setOpenCaution: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GroupContextMenu = ({ setOpenCaution }: Props) => {
  const { setIsEditModalOpened } = useGroupContext();
  return (
    <>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => {
                  setIsEditModalOpened(true);
                }}
                className={clsx(
                  'block w-full px-3 py-1 text-start text-sm leading-6 text-gray-900',
                  focus && 'bg-gray-50',
                )}
              >
                Edit
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ focus }) => (
              <button
                className={clsx(
                  'block w-full px-3 py-1 text-start text-sm leading-6 text-gray-900',
                  focus && 'bg-gray-50',
                )}
                onClick={() => setOpenCaution(true)}
              >
                Delete
              </button>
            )}
          </MenuItem>
        </MenuItems>
      </Transition>
    </>
  );
};
